import axios from 'axios';
import GlobalNotificationStatusStore from 'store/GlobalNotification';
import { isLocalhost } from 'utils/common';
// import { PRE_LOGIN_PAGE } from 'constants/common';

// export const baseURL = isLocalhost() ? 'https://dev.boxpractice.com/' : `${window.location.origin}/`;
export const baseURL = isLocalhost() ? 'https://test.boxpractice.com/' : `${window.location.origin}/`;
//export const baseURL = isLocalhost() ? 'https://app.kiwihealth.com/' : `${window.location.origin}/`;

const handleShowUnauthNotification = (response: any) => {
    if (response?.data?.code >= 4000 && response?.data?.code < 5000) {
        GlobalNotificationStatusStore.set('data', true);
    }
};

const instance = axios.create({
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
    },
    timeout: 1000 * 60,
    baseURL,
    withCredentials: false,
});

instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error.data.error.message);
    },
);

instance.interceptors.response.use(
    (config) => {
        if (config.status === 200) {
            return Promise.resolve(config);
        } else {
            return Promise.reject(config);
        }
    },
    async (error) => {
        const { response } = error;

        if (response) {
            const { config } = error;
            const [RETRY_COUNT, RETRY_DELAY] = [0, 1000];

            if (config && RETRY_COUNT) {
                config.__retryCount = config.__retryCount || 0;
                if (config.__retryCount >= RETRY_COUNT) {
                    return Promise.reject(response || { message: error.message });
                }
                config.__retryCount++;
                const rollback = new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, RETRY_DELAY || 1);
                });
                await rollback;
                return instance(config);
            }

            handleShowUnauthNotification(response);

            return Promise.reject(response);
        }
    },
);

export default instance;
