// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__describeList--9q8Hf {\n  display: flex;\n  flex-wrap: wrap;\n}\n.s-module__describeList--9q8Hf .s-module__describe--e3vy1 {\n  margin: 0 4px 4px 0;\n  padding: 2px 12px;\n  background: var(--gray-100);\n  border: 1px dashed #9CA3AF;\n  border-radius: 4px;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: var(--gray-800);\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ApplicationFormComponents/Practice/component/DescribeList/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;AACJ;AAHA;EAKQ,mBAAA;EACA,iBAAA;EACA,2BAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AACR","sourcesContent":[".describeList {\n    display: flex;\n    flex-wrap: wrap;\n\n    .describe {\n        margin: 0 4px 4px 0;\n        padding: 2px 12px;\n        background: var(--gray-100);\n        border: 1px dashed #9CA3AF;\n        border-radius: 4px;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 20px;\n        text-align: center;\n        color: var(--gray-800);\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"describeList": "s-module__describeList--9q8Hf",
	"describe": "s-module__describe--e3vy1"
};
export default ___CSS_LOADER_EXPORT___;
