/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import { Input, Collapse, Popover, InputNumber, Switch, Select, Tooltip, Checkbox, Button } from 'antd';
import { KlarityService, ServerMeta, AddressPublicType, EContactType, KlarityServiceTypeTemplate, KlarityServiceTypeValue, TKlarityStateTPrice, EServiceType, TKlarityPrice, ServerKlarityService, TServiceStateItem, TPatientGroupLimitation } from 'types/common';
import CommonTable from 'components/CommonTable';
import { ColumnsType } from 'antd/es/table';
import { getKlarityPriceLevel } from 'utils/service';
import channelProviderStore from 'store/ChannelApplicationProvider';
import { ESpecialty, SPECIALTY_TIPS_MAP } from 'constants/common';

import Icon1 from './images/mood1.svg';
import Icon2 from './images/mood2.svg';
import Icon3 from './images/mood3.svg';
import Icon4 from './images/mood4.svg';
import Icon5 from './images/mood5.svg';
import AvaIcon from 'assets/common/ava.png';
import { PracitceAddressForm } from 'types/practiceFront';
import { getUserId } from 'utils/localstore';
import { isSameStringArray } from 'data/provider';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';

type PigInfo = {
    states?: ServerMeta[],
    accept?: string[],
    contactType?: EContactType[],
    limitations?: string[],
    isAddingLimit?: boolean,
    pendingNewLimit?: string,
};

type StrMeta = {
    label: string,
    value: string
};

type IProps = {
    serviceTypeTemplate?: KlarityServiceTypeTemplate,
    serviceTypeTemplateValue?: KlarityServiceTypeValue,
    allStateConfigTypePriceData?: Record<string, TKlarityStateTPrice>,
    serviceType: EServiceType,
    service?: KlarityService;
    allowStates?: ServerMeta[];
    defaultOpen?: boolean;
    onServiceTypeInfoUpdate?: (info: ServerKlarityService) => void;
    onDisabled?: (disabled: boolean) => void;
    onNameIsExistedError?: (isExist: boolean) => void;
    allEditingNames: string[];
    editValue?: ServerKlarityService;
    isAdd?: boolean;
    onRemove?: () => void;
    isOps?: boolean;
    providerId?: number;
    addressList?: PracitceAddressForm[]
    allStatesFromService?: ServerMeta[];
    onInitVisitStateChange?: (states: ServerMeta[]) => void;
};

const ConfigServiceType = (props: IProps) => {
    const {
        addressList, isAdd, onRemove,
        serviceTypeTemplate, serviceTypeTemplateValue,
        allEditingNames, allStateConfigTypePriceData, serviceType, service,
        onServiceTypeInfoUpdate, onDisabled, defaultOpen, onNameIsExistedError,
        allowStates, editValue, isOps, providerId, onInitVisitStateChange,
        allStatesFromService,
    } = props;
    const pgiAcceptOption: StrMeta[] = serviceTypeTemplate?.klarityServiceTypePatientGroupList?.map((item) => {
        return {
            label: item.patientGroup,
            value: `${item.patientGroup}`,
        };
    }) || [];

    const pgiLmitOption: StrMeta[] = serviceTypeTemplate?.klarityServiceTypeLimitationList?.map((item) => {
        return {
            label: item.limitation,
            value: `${item.limitation}`,
        };
    }) || [];

    const contactTypeOption: StrMeta[] = [EContactType.TELE_HEALTH, EContactType.IN_PERSON]?.map((str) => { return { label: str, value: str }; });
    const [pgiLmitExtraOption, setPgiLmitExtraOption] = React.useState<StrMeta[]>([]);

    const [stateAddressIdsMap, setStateAddressIdsMap] = React.useState<Record<string, string[]>>({});
    const [stateContactTypeMap, setStateContactTypeMap] = React.useState<Record<string, EContactType[]>>({});

    const [pgiIsOpen, setPgiIsOpen] = React.useState(true);
    const [pendingPgiInfo, setPendingPgiInfo] = React.useState<PigInfo[]>([{}]);

    const [states, setStates] = React.useState<string[]>([]);
    const [initStatesFromEdit, setInitStatesFromEdit] = React.useState<string[]>([]);
    const [hasInitState, setHasInitState] = React.useState(false);
    const [pendingCustomizeName, setPendingCustomizeName] = React.useState<string>('');
    const [pendingDescription, setPendingDescription] = React.useState(serviceTypeTemplate?.description);
    const [nameIsExistInServerError, setNameIsExistInServerError] = React.useState(false);

    const [stateContentMyEaringMap, setStateContentMyEaringMap] = React.useState<Record<string, number>>({});
    const [stateContentDurationMap, setStateContentDurationMap] = React.useState<Record<string, number>>({});

    const [allStates] = useGetSettingData(EnumFields.STATE);

    const [isDisabled, setIsDisabled] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState<string[]>(defaultOpen ? [serviceTypeTemplate?.serviceType as string] : []);

    const [getProvider] = channelProviderStore.useStore();

    const displayName = serviceTypeTemplate?.displayName || serviceTypeTemplate?.serviceType || '';
    const ehrName = `${service?.specialtyName}${(pendingCustomizeName || '')}${serviceTypeTemplate?.displayName}`;
    const nameExistInEditing = allEditingNames && (allEditingNames.indexOf(ehrName) !== allEditingNames.lastIndexOf(ehrName));

    const uniprofileCreationData = getProvider('data');

    const addressOptions: PracitceAddressForm[] = (isOps && addressList) ? addressList : uniprofileCreationData?.practice?.addressList || [];
    const serviceId: number | undefined = editValue?.serviceId;
    const isEdit = !!serviceId;

    const isConsultation = serviceType === EServiceType.CONSULTATION;
    const consultationOverPrice = 10;
    const isInit = serviceTypeTemplate?.serviceType === EServiceType.INIT;
    const statesOption = isInit ? allStatesFromService : allowStates;

    let canEditPrice = true;
    let targetDate = '';

    let showConsultationFree = false;
    const priceUpdateAt = editValue?.priceUpdateAt;
    if (priceUpdateAt) {
        try {
            const updateTime = BigInt(new Date(priceUpdateAt).getTime());
            const nowTime = BigInt((new Date()).getTime());
            targetDate = dayjs(priceUpdateAt).add(30, 'day').format('MM/DD/YYYY');
            canEditPrice = (nowTime - updateTime) > 30 * 24 * 60 * 60 * 1000;
        } catch (e) {
            console.error(e);
        }
    }
    //canEditPrice = true;

    if (isConsultation) {
        if (!isEdit) {
            showConsultationFree = true;
        } else if (isEdit && editValue) {
            let hasNewAddstate = false;

            const hasZeroPrice = editValue?.serviceStateItems?.find((item) => item.earning === 0);
            if (hasZeroPrice) {
                showConsultationFree = true;
            }
            const editValueStateContent: string[] = editValue.serviceStateItems?.map((item) => {
                const target = (allStates as ServerMeta[])?.find((st: ServerMeta) => {
                    return st.content === item.state || st.dictKey === item.state;
                })
                return target?.content + '';
            });
            states?.forEach((st: string) => {
                if (!editValueStateContent.includes(st)) {
                    hasNewAddstate = true;
                }
            })
            if (hasNewAddstate) {
                showConsultationFree = true;
            }
        }
    }

    let allHasSelectState = true;
    let pendingPgiInfoStateLength = 0;
    pendingPgiInfo.forEach((info: PigInfo) => {
        pendingPgiInfoStateLength += (info?.states?.length || 0);
        if (!info.states || info.states.length === 0) {
            allHasSelectState = false;
        }
    });
    const allStateSeted = pendingPgiInfoStateLength === states?.length;


    const checkPendingServiceTypeName = async () => {
        if (editValue) {
            if (pendingCustomizeName === editValue.customizedServiceName) {
                setNameIsExistInServerError(false);
                return;
            }
            if (!pendingCustomizeName && !editValue.customizedServiceName) {
                setNameIsExistInServerError(false);
            }
        }
        // not need
        // const result = await checkServiceTypeName({
        //     customizedServiceName: pendingCustomizeName,
        //     serviceSpecialtyId: service?.id!,
        //     serviceTypeId: serviceTypeTemplate?.id!,
        // });
        // if (!result.error) {
        //     if (result?.data.data) {
        //         setNameIsExistInServerError(true);
        //         return;
        //     }
        // }
        // setNameIsExistInServerError(false);
    };

    React.useEffect(() => {
        if (!editValue) {
            checkPendingServiceTypeName();
        } else {
            setNameIsExistInServerError(false);
        }
    }, [editValue]);

    React.useEffect(() => {
        onNameIsExistedError?.(nameIsExistInServerError);
    }, [nameIsExistInServerError]);

    let keysStr: string = '';
    if (allStateConfigTypePriceData) {
        keysStr = Object.keys(allStateConfigTypePriceData)?.join(',');
    }

    React.useEffect(() => {
        if (allowStates && allStateConfigTypePriceData && Object.keys(allStateConfigTypePriceData).length > 0) {
            const allAllowStateContent = allowStates.map((item) => item.content);
            const allConfigStateContent = Object.keys(allStateConfigTypePriceData);
            const isSameArray = isSameStringArray(allAllowStateContent, allConfigStateContent);

            if (Object.keys(stateContentMyEaringMap).length < allowStates.length) {
                const _stateContentMyEaringMap: Record<string, number> = {};
                allowStates.forEach((st: ServerMeta) => {
                    const targetPrice: TKlarityPrice = allStateConfigTypePriceData[st.content]?.[serviceTypeTemplate?.serviceType!];
                    const oldPrice = (stateContentMyEaringMap && st?.content) ? stateContentMyEaringMap[st.content] : undefined;
                    let hasOldPrice = false;
                    if (oldPrice !== null && oldPrice !== undefined) {
                        hasOldPrice = true;
                    }
                    _stateContentMyEaringMap[st.content!] = hasOldPrice ? oldPrice! : (targetPrice?.krc || 0);
                });
                setStateContentMyEaringMap(_stateContentMyEaringMap);
            }
        }
    }, [allowStates, allStateConfigTypePriceData, stateContentMyEaringMap, serviceTypeTemplate, keysStr]);

    React.useEffect(() => {
        if (!isInit || isEdit) {
            return;
        }
        if (states && allStateConfigTypePriceData && Object.keys(allStateConfigTypePriceData).length > 0) {
            const allConfigStateContent = Object.keys(allStateConfigTypePriceData);
            const isSameArray = isSameStringArray(states, allConfigStateContent);

            if (isSameArray) {
                const _stateContentMyEaringMap: Record<string, number> = {};
                states.forEach((st: string) => {
                    const targetPrice: TKlarityPrice = allStateConfigTypePriceData[st]?.[serviceTypeTemplate?.serviceType!];
                    _stateContentMyEaringMap[st!] = targetPrice?.krc || 0;
                });
                setStateContentMyEaringMap(_stateContentMyEaringMap);
            }
        }
    }, [isInit, states, allStateConfigTypePriceData, allStates, isEdit]);

    React.useEffect(() => {
        if (allowStates && editValue && states.length === 0) {
            //allow state is get from current value
            const currentEditItemStateContent = editValue.serviceStateItems?.map((item) => item.state);
            const currentEditItemState = allowStates.filter((item) => currentEditItemStateContent?.includes(item.dictKey) || currentEditItemStateContent?.includes(item.content));
            const isInitVisitServer = editValue.serviceType === EServiceType.INIT;
            setInitStatesFromEdit((isInitVisitServer ? allowStates : currentEditItemState)?.map((item) => item.content));
            setStates((isInitVisitServer ? allowStates : currentEditItemState)?.map((item) => item.content));
            setPendingCustomizeName(editValue?.customizedServiceName || '');
            setPendingDescription(editValue?.description || '');

            //use edit value
            //pgiLmitExtraOption
            if (editValue.patientGroupLimitations && editValue.patientGroupLimitations.length > 0) {
                const allLimitStr: string[] = [];
                editValue.patientGroupLimitations.forEach((item) => {
                    item.limitation?.forEach((str: string) => {
                        if (!allLimitStr.includes(str)) {
                            allLimitStr.push(str);
                        }
                    });
                });

                const _pgiLmitOption = serviceTypeTemplate?.klarityServiceTypeLimitationList?.map((item) => {
                    return {
                        label: item.limitation,
                        value: `${item.limitation}`,
                    };
                }) || [];
                const filtered = allLimitStr.filter((str: string) => {
                    const exist = _pgiLmitOption?.find((item) => {
                        return item.label === str || item.value === str;
                    });
                    return !exist;
                });
                setPgiLmitExtraOption(filtered?.map((str: string) => {
                    return {
                        label: str,
                        value: str,
                    };
                }));
            }

            //address

            const _stateAddressIdsMap: Record<string, string[]> = {};
            const _stateContactTypeMap: Record<string, EContactType[]> = {};
            allowStates.forEach((state: ServerMeta) => {
                _stateContactTypeMap[state.content] = [];
                const targetSeviceState: TServiceStateItem | undefined = editValue?.serviceStateItems?.find((item: TServiceStateItem) => {
                    return item.state === state.dictKey || item.state === state.content;
                });
                if (targetSeviceState && targetSeviceState.teleHealth) {
                    _stateContactTypeMap[state.content].push(EContactType.TELE_HEALTH);
                }
                if (targetSeviceState && targetSeviceState.inPerson) {
                    _stateContactTypeMap[state.content].push(EContactType.IN_PERSON);
                }
                if (_stateContactTypeMap[state.content].includes(EContactType.IN_PERSON)) {
                    _stateAddressIdsMap[state.content] = targetSeviceState?.practiceAddressIds || [];
                } else {
                    _stateAddressIdsMap[state.content] = [];
                }
            });
            setStateAddressIdsMap(_stateAddressIdsMap);
            setStateContactTypeMap(_stateContactTypeMap);

            if (editValue?.patientGroupLimitations && editValue?.patientGroupLimitations?.length > 0) {
                setPgiIsOpen(true);
                //pendingPgiInfo.
                const _pendingPgiInfo: PigInfo[] = [];
                editValue?.patientGroupLimitations.forEach((item: TPatientGroupLimitation) => {
                    const contactType: EContactType[] = [];
                    if (item.appliedInPerson) {
                        contactType.push(EContactType.IN_PERSON);
                    }
                    if (item.appliedTeleHealth) {
                        contactType.push(EContactType.TELE_HEALTH);
                    }
                    const stateKeys = item.stateGroup || [];
                    const pigStates = allowStates?.filter((st: ServerMeta) => {
                        return stateKeys.includes(st.dictKey) || stateKeys.includes(st.content);
                    });
                    const add: PigInfo = {
                        states: pigStates,
                        accept: item.patientGroup || [],
                        contactType,
                        limitations: item.limitation || [],
                    };
                    _pendingPgiInfo.push(add);
                });
                setPendingPgiInfo(_pendingPgiInfo);
            }

            if (editValue?.serviceStateItems) {
                const _stateContentMyEaringMap: Record<string, number> = {};
                const _stateContentDurationMap: Record<string, number> = {};
                allowStates.forEach((st: ServerMeta) => {
                    const targetItem: TServiceStateItem | undefined = editValue?.serviceStateItems?.find((item) => {
                        return item.state === st.content || item.state === st.dictKey;
                    });
                    _stateContentMyEaringMap[st.content!] = targetItem?.earning || 0;
                    _stateContentDurationMap[st.content!] = (targetItem?.duration !== null && targetItem?.duration !== undefined) ? targetItem?.duration : 30;
                });
                setStateContentMyEaringMap(_stateContentMyEaringMap);
                setStateContentDurationMap(_stateContentDurationMap);
            }

            return;
        }
        if (allowStates && serviceTypeTemplateValue) {
            const _stateContentDurationMap: Record<string, number> = {};
            allowStates.forEach((st: ServerMeta) => {
                _stateContentDurationMap[st.content!] = serviceTypeTemplateValue?.duration || serviceTypeTemplate?.defaultDuration || 30;
            });
            setStateContentDurationMap(_stateContentDurationMap);
        }
    }, [allowStates, serviceTypeTemplateValue, serviceTypeTemplate, editValue]);

    React.useEffect(() => {
        if (serviceTypeTemplateValue && !editValue) {
            const _pendingPgiInfo = [...pendingPgiInfo];
            _pendingPgiInfo.forEach((item) => {
                const patientGroupIds = serviceTypeTemplateValue.patientGroupIds?.split(',');
                const limitationIds = serviceTypeTemplateValue.limitationIds?.split(',') || [];
                item.accept = serviceTypeTemplate?.klarityServiceTypePatientGroupList?.filter((i) => {
                    return patientGroupIds.includes(`${i.id}`);
                })?.map((i) => i.patientGroup) || [];
                item.limitations = serviceTypeTemplate?.klarityServiceTypeLimitationList?.filter((i) => {
                    return limitationIds.includes(`${i.id}`);
                })?.map((i) => i.limitation) || [];
            });
            setPendingPgiInfo(_pendingPgiInfo);
        }
    }, [serviceTypeTemplateValue, editValue]);

    React.useEffect(() => {
        if (!hasInitState && allowStates && allowStates.length > 0 && !isEdit) {
            setStates(allowStates.map((item) => item.content));
            setHasInitState(true);
        }
    }, [allowStates, hasInitState, isEdit]);

    React.useEffect(() => {
        const _addressOptions: PracitceAddressForm[] = uniprofileCreationData?.practice?.addressList || [];
        if (_addressOptions.length > 0 && allowStates && allowStates.length > 0 && !isEdit) {
            const _stateAddressIdsMap: Record<string, string[]> = {};
            const _stateContactTypeMap: Record<string, EContactType[]> = {};
            allowStates.forEach((state: ServerMeta) => {
                _stateContactTypeMap[state.content] = [];
                if (serviceTypeTemplateValue?.teleHealth) {
                    _stateContactTypeMap[state.content].push(EContactType.TELE_HEALTH);
                }
                if (serviceTypeTemplateValue?.inPerson) {
                    _stateContactTypeMap[state.content].push(EContactType.IN_PERSON);
                }
                if (_stateContactTypeMap[state.content].includes(EContactType.IN_PERSON)) {
                    const addData = _addressOptions.filter((add) => {
                        return (add.practiceState?.toLowerCase() === state.dictKey.toLowerCase() || add.practiceState?.toLowerCase() === state.content.toLowerCase()) && [AddressPublicType.INVITATION, AddressPublicType.WALK_IN].includes(add.publicType);
                    })?.map((add) => `${add.id}`);
                    _stateAddressIdsMap[state.content] = addData;
                } else {
                    _stateAddressIdsMap[state.content] = [];
                }
            });
            setStateAddressIdsMap(_stateAddressIdsMap);
            setStateContactTypeMap(_stateContactTypeMap);
        }
    }, [allowStates, uniprofileCreationData, isEdit]);

    React.useEffect(() => {
        //push update
        // serviceId if edit
        const data: Partial<ServerKlarityService> = {
            serviceSpecialtyName: service?.specialtyName,
            serviceSpecialtyId: `${service?.id}`,
            serviceTypeId: serviceTypeTemplate?.id,
            serviceType: serviceTypeTemplate?.serviceType,
            providerId: (providerId && isOps) ? providerId : getUserId(),
            customizedServiceName: nameIsExistInServerError ? '' : pendingCustomizeName,
            description: pendingDescription,
            serviceStateItems: [],
            patientGroupLimitations: [],
        };
        if (isEdit) {
            data.serviceId = serviceId;
        }
        states?.forEach((state) => {
            const addressIds = stateAddressIdsMap[state];
            const contactTypes = stateContactTypeMap[state];
            const duration = stateContentDurationMap[state];
            const myEarning = stateContentMyEaringMap[state];
            const mmu = allStateConfigTypePriceData?.[state]?.[serviceTypeTemplate?.serviceType!]?.mmu || 0;
            let pf = allStateConfigTypePriceData?.[state]?.[serviceTypeTemplate?.serviceType!]?.pf || 0;
            if (isConsultation && pf === 0 && myEarning > consultationOverPrice) {
                pf = allStateConfigTypePriceData?.[state]?.[EServiceType.INIT]?.pf || 0;
            }
            const total = myEarning + mmu + pf;

            const targetState: ServerMeta | undefined = allowStates?.find((item) => item.content === state || item.dictKey === state);
            const serviceStateItem: TServiceStateItem = {
                //serviceId: service?.id!,
                state: targetState?.dictKey!, //'CA',
                duration,
                earning: myEarning,
                marketingMarkup: mmu,
                platformFee: pf,
                patientCost: Number.isNaN(total) ? 0 : total,
                practiceAddressIds: addressIds?.map((id) => `${id}`) || [],
                teleHealth: !!(contactTypes?.includes(EContactType.TELE_HEALTH)),
                inPerson: !!(contactTypes?.includes(EContactType.IN_PERSON)),
            };
            data.serviceStateItems?.push(serviceStateItem);
        });

        pendingPgiInfo.forEach((item: PigInfo) => {
            const selectState = allowStates?.filter((state) => {
                return item.states?.find((st) => st.content === state.content || st.dictKey === state.content);
            });
            if (selectState && selectState.length > 0) {
                const patientGroupLimitation: TPatientGroupLimitation = {
                    //serviceId: service?.id!,
                    stateGroup: selectState?.map((st) => st.dictKey!) || [],
                    patientGroup: item?.accept?.filter((val) => val !== 'all').map((val) => `${val}`) || [],
                    limitation: item?.limitations?.map((val) => `${val}`) || [],
                    appliedTeleHealth: !!(item.contactType?.includes(EContactType.TELE_HEALTH)),
                    appliedInPerson: !!(item.contactType?.includes(EContactType.IN_PERSON)),
                };
                data.patientGroupLimitations?.push(patientGroupLimitation);
            }
        });
        onServiceTypeInfoUpdate?.(data as ServerKlarityService);
    }, [nameIsExistInServerError, pendingCustomizeName, pendingDescription, serviceTypeTemplate, serviceTypeTemplateValue, service?.specialtyName, service?.id, states, stateAddressIdsMap, stateContactTypeMap, stateContentMyEaringMap, pendingPgiInfo, allStateConfigTypePriceData, allowStates, stateContentDurationMap]);

    const onAddressChange = (state: ServerMeta, addressIds: string[]) => {
        const _stateAddressIdsMap = { ...stateAddressIdsMap };
        _stateAddressIdsMap[state.content] = addressIds;
        setStateAddressIdsMap(_stateAddressIdsMap);
    };

    const onTeleChange = (state: ServerMeta, checked: boolean) => {
        const _stateContactTypeMap = { ...stateContactTypeMap };
        const current = _stateContactTypeMap[state.content] || [];
        if (checked && !current.includes(EContactType.TELE_HEALTH)) {
            current.push(EContactType.TELE_HEALTH);
            _stateContactTypeMap[state.content] = [...current];
        } else if (!checked) {
            _stateContactTypeMap[state.content] = current.filter((item) => item !== EContactType.TELE_HEALTH);
        }
        setStateContactTypeMap(_stateContactTypeMap);
    };

    const onInPersonChange = (state: ServerMeta, checked: boolean) => {
        const _stateContactTypeMap = { ...stateContactTypeMap };
        const current = _stateContactTypeMap[state.content] || [];
        if (checked && !current.includes(EContactType.IN_PERSON)) {
            current.push(EContactType.IN_PERSON);
        } else if (!checked) {
            _stateContactTypeMap[state.content] = current.filter((item) => item !== EContactType.IN_PERSON);
        }
        setStateContactTypeMap(_stateContactTypeMap);
    };

    const onPigStateChange = (inx: number, val: ServerMeta[]) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].states = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigContactTypeChange = (inx: number, val: EContactType[]) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].contactType = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigAcceptChange = (inx: number, val: string[]) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].accept = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigLimitChange = (inx: number, val: string[]) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].limitations = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigNewLimitChange = (inx: number, val: string) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].pendingNewLimit = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigNewLimitChangeConfirm = (inx: number) => {
        const _pendingPgiInfo = [...pendingPgiInfo];

        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].isAddingLimit = false;
            const newLimit = _pendingPgiInfo[inx].pendingNewLimit;
            _pendingPgiInfo[inx].pendingNewLimit = '';
            if (!_pendingPgiInfo[inx].limitations?.includes(newLimit!)) {
                _pendingPgiInfo[inx].limitations?.push(newLimit!);
            }
            if (newLimit) {
                setPgiLmitExtraOption([...pgiLmitExtraOption, { label: newLimit!, value: newLimit! }]);
            }
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    const onPigIsAddingLimitChange = (inx: number, val: boolean) => {
        const _pendingPgiInfo = [...pendingPgiInfo];
        if (_pendingPgiInfo.length > inx) {
            _pendingPgiInfo[inx].isAddingLimit = val;
        }
        setPendingPgiInfo(_pendingPgiInfo);
    };

    React.useEffect(() => {
        if (serviceTypeTemplate?.mandatory === false && !editValue && !isAdd) {
            setIsDisabled(true);
            onDisabled?.(true);
        } else if (editValue) {
            setIsDisabled(!!editValue.disableFlag);
            onDisabled?.(!!editValue.disableFlag);
        }
    }, [serviceTypeTemplate?.mandatory, editValue]);

    let tips1 = 'Suggested duration: 30 minutes (based on our research). Duration can vary from 10 to 120 minutes depending on your specific service needs.';
    const tips2 = 'Your expected payment for services rendered to patients, reflecting the total amount to be deposited into your bank account';
    const tips3 = "Klarity's additional charge for marketing your services to patients";
    const tips4 = 'Costs associated with listing your services on various channels.';
    const tips5 = 'The complete cost a patient will pay for your service package.';
    const tips6 = "Ava's price recommendation for you, grounded in our comprehensive market research.";
    if (serviceType === EServiceType.CONSULTATION) {
        tips1 = 'Suggested duration: 10 minutes (based on our research). Duration can vary from 5 to 10 minutes depending on your specific service needs.';
    }
    if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
        tips1 = 'Suggested duration: 20 minutes (based on our research). Duration can vary from 10 to 120 minutes depending on your specific service needs.';
    }
    if (serviceType === EServiceType.MEDICATION_REFILL) {
        tips1 = 'Suggested duration: 10 minutes (based on our research). Duration can vary from 5 to 30 minutes depending on your specific service needs.';
    }
    if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
        tips1 = 'Suggested duration: 10 minutes (based on our research). Duration can vary from 5 to 60 minutes depending on your specific service needs.';
    }

    const targetNameTips: undefined | { reminder: string, toolTips: string } = SPECIALTY_TIPS_MAP[service?.specialtyName as ESpecialty];
    const hasNameTips = !!targetNameTips;

    const originalPgiAcceptOption: StrMeta[] = [...pgiAcceptOption];
    if (originalPgiAcceptOption.length > 0) {
        originalPgiAcceptOption.push({ label: 'All above', value: 'all' });
    }
    const numRows = Math.ceil(originalPgiAcceptOption.length / 2);

    const column1 = originalPgiAcceptOption.slice(0, numRows);
    const column2 = originalPgiAcceptOption.slice(numRows);

    const updatedPgiAcceptOption: StrMeta[] = [];
    let updatedPgiAcceptOptionInx = 0;
    while (updatedPgiAcceptOptionInx < column1.length) {
        if (column1[updatedPgiAcceptOptionInx]) {
            updatedPgiAcceptOption.push(column1[updatedPgiAcceptOptionInx]);
        }
        if (column2[updatedPgiAcceptOptionInx]) {
            updatedPgiAcceptOption.push(column2[updatedPgiAcceptOptionInx]);
        }
        updatedPgiAcceptOptionInx++;
    }

    const klarityServicePriceDataColumns: ColumnsType<ServerMeta> = [
        {
            title: (<div className={s.tableTitle}>STATES</div>),
            width: '120px',
            render: (state: ServerMeta) => {
                return (
                    <div className={s.colState}>
                        {state.content}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>DURATION(MINS)</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips1}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            width: '150px',
            render: (item: ServerMeta) => {
                const defalutVal = (serviceTypeTemplateValue?.duration !== undefined && serviceTypeTemplateValue?.duration !== null) ? serviceTypeTemplateValue.duration : (serviceTypeTemplate?.defaultDuration || 30);

                return (
                    <div className={s.colDurWrap}>
                        <InputNumber
                            step={1}
                            value={stateContentDurationMap[item.content]}
                            onChange={(e) => {
                                const _stateContentDurationMap = { ...stateContentDurationMap };
                                _stateContentDurationMap[item.content] = e!;
                                setStateContentDurationMap(_stateContentDurationMap);
                            }}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    const _stateContentDurationMap = { ...stateContentDurationMap };
                                    _stateContentDurationMap[item.content] = defalutVal!;
                                    setStateContentDurationMap(_stateContentDurationMap);
                                } else {
                                    let val = parseInt(e.target.value, 10);
                                    const min = serviceTypeTemplate?.minDuration || 0;
                                    const max = serviceTypeTemplate?.maxDuration || 1000;
                                    if (val > max) {
                                        val = max;
                                    } else if (val < min) {
                                        val = min;
                                    }
                                    const _stateContentDurationMap = { ...stateContentDurationMap };
                                    _stateContentDurationMap[item.content] = val!;
                                    setStateContentDurationMap(_stateContentDurationMap);
                                }
                            }}
                            size="small"
                            defaultValue={defalutVal}
                            max={serviceTypeTemplate?.maxDuration || 1000}
                            min={serviceTypeTemplate?.minDuration || 0}
                        />
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>MY EARNINGS</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips2}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            width: '150px',
            render: (item: ServerMeta) => {
                const statePrice = allStateConfigTypePriceData?.[item.content];
                let defaultVal = statePrice?.[EServiceType.INIT]?.krc;
                if (serviceType === EServiceType.CONSULTATION) {
                    defaultVal = statePrice?.[EServiceType.CONSULTATION]?.krc;
                }
                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
                    defaultVal = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.krc;
                }
                if (serviceType === EServiceType.MEDICATION_REFILL) {
                    defaultVal = statePrice?.[EServiceType.MEDICATION_REFILL]?.krc;
                }
                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
                    defaultVal = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.krc;
                }
                let stateIsNewAdd = false;
                if (isEdit && editValue) {
                    stateIsNewAdd = !editValue.serviceStateItems?.find((st) => st.state === item.dictKey || st.state === item.content);
                }
                const isConsultation = serviceType === EServiceType.CONSULTATION;
                const target = editValue?.serviceStateItems?.find((st) => st.state === item.dictKey || st.state === item.content);
                const hasOldConsultationPrice = isEdit && target && target.earning > 0;

                if (target) {
                    defaultVal = target.earning;
                }
                return (
                    <div className={s.colDurWrap}>
                        <InputNumber
                            disabled={(isConsultation && !hasOldConsultationPrice) || (!canEditPrice && !stateIsNewAdd)}
                            value={stateContentMyEaringMap[item.content]}
                            onChange={(e) => {
                                const _stateContentMyEaringMap = { ...stateContentMyEaringMap };
                                _stateContentMyEaringMap[item.content] = e!;
                                setStateContentMyEaringMap(_stateContentMyEaringMap);
                            }}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    const _stateContentMyEaringMap = { ...stateContentMyEaringMap };
                                    _stateContentMyEaringMap[item.content] = defaultVal!;
                                    setStateContentMyEaringMap(_stateContentMyEaringMap);
                                } else {
                                    let val = parseInt(e.target.value, 10);
                                    const min = 0;
                                    const max = 9999;
                                    if (val > max) {
                                        val = max;
                                    } else if (val < min) {
                                        val = min;
                                    }
                                    const _stateContentMyEaringMap = { ...stateContentMyEaringMap };
                                    _stateContentMyEaringMap[item.content] = val!;
                                    setStateContentMyEaringMap(_stateContentMyEaringMap);
                                }
                            }}
                            size="small"
                            max={9999}
                            min={0}
                            defaultValue={defaultVal}
                        />
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>MARKETING MARKUP</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips3}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            width: '120px',
            render: (item: ServerMeta) => {
                const statePrice = allStateConfigTypePriceData?.[item.content];
                let val = statePrice?.[EServiceType.INIT]?.mmu;
                if (serviceType === EServiceType.CONSULTATION) {
                    val = statePrice?.[EServiceType.CONSULTATION]?.mmu;
                }
                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
                    val = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu;
                }
                if (serviceType === EServiceType.MEDICATION_REFILL) {
                    val = statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu;
                }
                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
                    val = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu;
                }

                const display = `$${val}`;
                if (serviceType === EServiceType.CONSULTATION && (statePrice?.[EServiceType.INIT]?.mmu || 0) > (statePrice?.[EServiceType.CONSULTATION]?.mmu || 0)) {
                    return (
                        <div className={s.colVal}>
                            <span className={s.v1}>${statePrice?.[EServiceType.INIT]?.mmu}</span>
                            <span className={s.move}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                    <path d="M20.8848 4.35355C21.0801 4.15829 21.0801 3.84171 20.8848 3.64645L17.7028 0.464466C17.5076 0.269204 17.191 0.269204 16.9957 0.464466C16.8005 0.659728 16.8005 0.976311 16.9957 1.17157L19.8241 4L16.9957 6.82843C16.8005 7.02369 16.8005 7.34027 16.9957 7.53553C17.191 7.7308 17.5076 7.7308 17.7028 7.53553L20.8848 4.35355ZM0.03125 4.5H20.5312V3.5H0.03125V4.5Z" fill="#00816B" />
                                </svg>
                            </span>
                            <span className={s.v2}>${statePrice?.[EServiceType.CONSULTATION]?.mmu}</span>
                        </div>
                    );
                }

                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT && (statePrice?.[EServiceType.INIT]?.mmu || 0) > (statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu || 0)) {
                    return (
                        <div className={s.colVal}>
                            <span className={s.v1}>${statePrice?.[EServiceType.INIT]?.mmu}</span>
                            <span className={s.move}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                    <path d="M20.8848 4.35355C21.0801 4.15829 21.0801 3.84171 20.8848 3.64645L17.7028 0.464466C17.5076 0.269204 17.191 0.269204 16.9957 0.464466C16.8005 0.659728 16.8005 0.976311 16.9957 1.17157L19.8241 4L16.9957 6.82843C16.8005 7.02369 16.8005 7.34027 16.9957 7.53553C17.191 7.7308 17.5076 7.7308 17.7028 7.53553L20.8848 4.35355ZM0.03125 4.5H20.5312V3.5H0.03125V4.5Z" fill="#00816B" />
                                </svg>
                            </span>
                            <span className={s.v2}>${statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu}</span>
                        </div>
                    );
                }

                if (serviceType === EServiceType.MEDICATION_REFILL && (statePrice?.[EServiceType.INIT]?.mmu || 0) > (statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu || 0)) {
                    return (
                        <div className={s.colVal}>
                            <span className={s.v1}>${statePrice?.[EServiceType.INIT]?.mmu}</span>
                            <span className={s.move}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                    <path d="M20.8848 4.35355C21.0801 4.15829 21.0801 3.84171 20.8848 3.64645L17.7028 0.464466C17.5076 0.269204 17.191 0.269204 16.9957 0.464466C16.8005 0.659728 16.8005 0.976311 16.9957 1.17157L19.8241 4L16.9957 6.82843C16.8005 7.02369 16.8005 7.34027 16.9957 7.53553C17.191 7.7308 17.5076 7.7308 17.7028 7.53553L20.8848 4.35355ZM0.03125 4.5H20.5312V3.5H0.03125V4.5Z" fill="#00816B" />
                                </svg>
                            </span>
                            <span className={s.v2}>${statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu}</span>
                        </div>
                    );
                }

                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT && (statePrice?.[EServiceType.INIT]?.mmu || 0) > (statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu || 0)) {
                    return (
                        <div className={s.colVal}>
                            <span className={s.v1}>${statePrice?.[EServiceType.INIT]?.mmu}</span>
                            <span className={s.move}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                    <path d="M20.8848 4.35355C21.0801 4.15829 21.0801 3.84171 20.8848 3.64645L17.7028 0.464466C17.5076 0.269204 17.191 0.269204 16.9957 0.464466C16.8005 0.659728 16.8005 0.976311 16.9957 1.17157L19.8241 4L16.9957 6.82843C16.8005 7.02369 16.8005 7.34027 16.9957 7.53553C17.191 7.7308 17.5076 7.7308 17.7028 7.53553L20.8848 4.35355ZM0.03125 4.5H20.5312V3.5H0.03125V4.5Z" fill="#00816B" />
                                </svg>
                            </span>
                            <span className={s.v2}>${statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu}</span>
                        </div>
                    );
                }
                return (
                    <div className={s.colVal}>
                        {display}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>PLATFORM FEE</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips4}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            width: '120px',
            render: (item: ServerMeta) => {
                const statePrice = allStateConfigTypePriceData?.[item.content];
                let val = statePrice?.[EServiceType.INIT]?.pf;
                if (serviceType === EServiceType.CONSULTATION) {
                    val = statePrice?.[EServiceType.CONSULTATION]?.pf;
                }
                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
                    val = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.pf;
                }
                if (serviceType === EServiceType.MEDICATION_REFILL) {
                    val = statePrice?.[EServiceType.MEDICATION_REFILL]?.pf;
                }
                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
                    val = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.pf;
                }
                const display = `$${val}`;

                const earning = stateContentMyEaringMap[item.content];
                if (earning && isConsultation && earning > consultationOverPrice && val === 0) {
                    //use init PLATFORM FEE
                    return (
                        <div className={s.colVal}>
                            {`$${statePrice?.[EServiceType.INIT]?.pf}`}
                        </div>
                    );
                }

                if (serviceType === EServiceType.CONSULTATION && (statePrice?.[EServiceType.INIT]?.pf || 0) > (statePrice?.[EServiceType.CONSULTATION]?.pf || 0)) {
                    return (
                        <div className={s.colVal}>
                            <span className={s.v1}>${statePrice?.[EServiceType.INIT]?.pf}</span>
                            <span className={s.move}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="8" viewBox="0 0 22 8" fill="none">
                                    <path d="M20.8848 4.35355C21.0801 4.15829 21.0801 3.84171 20.8848 3.64645L17.7028 0.464466C17.5076 0.269204 17.191 0.269204 16.9957 0.464466C16.8005 0.659728 16.8005 0.976311 16.9957 1.17157L19.8241 4L16.9957 6.82843C16.8005 7.02369 16.8005 7.34027 16.9957 7.53553C17.191 7.7308 17.5076 7.7308 17.7028 7.53553L20.8848 4.35355ZM0.03125 4.5H20.5312V3.5H0.03125V4.5Z" fill="#00816B" />
                                </svg>
                            </span>
                            <span className={s.v2}>${statePrice?.[EServiceType.CONSULTATION]?.pf}</span>
                        </div>
                    );
                }
                return (
                    <div className={s.colVal}>
                        {display}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>PATIENT COST</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips5}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            width: '120px',
            render: (item: ServerMeta) => {
                const statePrice = allStateConfigTypePriceData?.[item.content];

                let defaultVal = statePrice?.[EServiceType.INIT]?.krc;
                let mmuPrice = statePrice?.[EServiceType.INIT]?.mmu;
                let pfPrice = statePrice?.[EServiceType.INIT]?.pf;
                if (serviceType === EServiceType.CONSULTATION) {
                    defaultVal = statePrice?.[EServiceType.CONSULTATION]?.krc;
                    mmuPrice = statePrice?.[EServiceType.CONSULTATION]?.mmu;
                    pfPrice = statePrice?.[EServiceType.CONSULTATION]?.pf;

                    const earning = stateContentMyEaringMap[item.content];
                    if (earning && isConsultation && earning > consultationOverPrice && pfPrice === 0) {
                        pfPrice = statePrice?.[EServiceType.INIT]?.pf;
                    }
                }
                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
                    defaultVal = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.krc;
                    mmuPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu;
                    pfPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.pf;
                }
                if (serviceType === EServiceType.MEDICATION_REFILL) {
                    defaultVal = statePrice?.[EServiceType.MEDICATION_REFILL]?.krc;
                    mmuPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu;
                    pfPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.pf;
                }
                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
                    defaultVal = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.krc;
                    mmuPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu;
                    pfPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.pf;
                }

                const total = (stateContentMyEaringMap[item.content] !== undefined && stateContentMyEaringMap[item.content] !== null) ? stateContentMyEaringMap[item.content] + mmuPrice! + pfPrice! : 0;
                return (
                    <div className={s.colVal}>
                        ${total}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>AVA PRICE INSIGHTS</span>
                    <span className={s.icon}>
                        <Popover content={<div className={s.toolWrap}>{tips6}</div>} trigger="hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Popover>

                    </span>
                </div>
            ),
            render: (val, item: ServerMeta) => {
                const statePrice = allStateConfigTypePriceData?.[item.content];

                let defaultVal = statePrice?.[EServiceType.INIT]?.krc;
                let mmuPrice = statePrice?.[EServiceType.INIT]?.mmu;
                let pfPrice = statePrice?.[EServiceType.INIT]?.pf;
                let recommendPriceArea = statePrice?.[EServiceType.INIT]?.recommendPriceArea;
                if (serviceType === EServiceType.CONSULTATION) {
                    defaultVal = statePrice?.[EServiceType.CONSULTATION]?.krc;
                    mmuPrice = statePrice?.[EServiceType.CONSULTATION]?.mmu;
                    pfPrice = statePrice?.[EServiceType.CONSULTATION]?.pf;
                    recommendPriceArea = statePrice?.[EServiceType.CONSULTATION]?.recommendPriceArea;

                    const earning = stateContentMyEaringMap[item.content];
                    if (earning && isConsultation && earning > consultationOverPrice && pfPrice === 0) {
                        pfPrice = statePrice?.[EServiceType.INIT]?.pf;
                        recommendPriceArea = statePrice?.[EServiceType.CONSULTATION_OVERCHARGE]?.recommendPriceArea;
                    }
                }
                if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
                    defaultVal = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.krc;
                    mmuPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu;
                    pfPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.pf;
                    recommendPriceArea = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.recommendPriceArea;
                }
                if (serviceType === EServiceType.MEDICATION_REFILL) {
                    defaultVal = statePrice?.[EServiceType.MEDICATION_REFILL]?.krc;
                    mmuPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu;
                    pfPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.pf;
                    recommendPriceArea = statePrice?.[EServiceType.MEDICATION_REFILL]?.recommendPriceArea;
                }
                if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
                    defaultVal = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.krc;
                    mmuPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu;
                    pfPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.pf;
                    recommendPriceArea = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.recommendPriceArea;
                }

                const total = (stateContentMyEaringMap[item.content] !== undefined && stateContentMyEaringMap[item.content] !== null) ? stateContentMyEaringMap[item.content] + mmuPrice! + pfPrice! : undefined;

                const [level, percent] = getKlarityPriceLevel(total, recommendPriceArea);
                let tipsClass = s.tips;
                let tipText = '';

                if (level === 1) {
                    tipsClass = `${s.tips} ${s.tipsLevel1}`;
                    tipText = `$${total} is very competitive in your area`;
                }
                if (level === 2) {
                    tipsClass = `${s.tips} ${s.tipsLevel2}`;
                    tipText = `$${total} is competitive in your area`;
                }
                if (level === 3) {
                    tipsClass = `${s.tips} ${s.tipsLevel3}`;
                    tipText = `$${total} is average in your area`;
                }
                if (level === 4) {
                    tipsClass = `${s.tips} ${s.tipsLevel4}`;
                    tipText = `$${total} is high in your area`;
                }
                if (level === 5) {
                    tipsClass = `${s.tips} ${s.tipsLevel5}`;
                    tipText = `$${total} is absolutely not competitive`;
                }
                return (
                    <div className={s.barWrap}>
                        <div className={s.bar}>
                            <div className={s.bar1} />
                            <div className={s.bar2} />
                            <div className={s.bar3} />
                            <div className={s.bar4} />
                            <div className={s.bar5} />
                        </div>
                        <div className={s.icon} style={{ left: `${percent}%` }}>
                            {
                                level === 1 && <img src={Icon1} />
                            }
                            {
                                level === 2 && <img src={Icon2} />
                            }
                            {
                                level === 3 && <img src={Icon3} />
                            }
                            {
                                level === 4 && <img src={Icon4} />
                            }
                            {
                                level === 5 && <img src={Icon5} />
                            }
                        </div>
                        <div className={tipsClass}>
                            {tipText}
                        </div>
                    </div>
                );
            },
        },
    ];

    const contactDataColumns: ColumnsType<ServerMeta> = [
        {
            title: (<div className={s.tableTitle}>STATES</div>),
            dataIndex: 'dictKey',
            key: 'dictKey',
            width: '120px',
            render: (state: string) => {
                return (
                    <div className={s.colState}>
                        {state}
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    TELE-HEALTH
                </div>
            ),
            width: '150px',
            render: (val, item: ServerMeta) => {
                const contactSupport = stateContactTypeMap[item.content] || [];
                const check = contactSupport.includes(EContactType.TELE_HEALTH);
                return (
                    <div className={s.colDurWrap}>
                        <Switch
                            checked={check}
                            onChange={(e) => {
                                onTeleChange(item, e);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    IN PERSON
                </div>
            ),
            width: '150px',
            render: (val, item: ServerMeta) => {
                const contactSupport = stateContactTypeMap[item.content] || [];
                const check = contactSupport.includes(EContactType.IN_PERSON);

                const allowAddressOptions = addressOptions?.filter((add: PracitceAddressForm) => {
                    return (add.practiceState?.toLowerCase() === item.dictKey.toLowerCase() || add.practiceState?.toLowerCase() === item.content.toLowerCase()) && [AddressPublicType.INVITATION, AddressPublicType.WALK_IN].includes(add.publicType);
                });

                const showTips = allowAddressOptions?.length === 0;
                const isRemoveAddressButChecked = isEdit && check && showTips;
                if (showTips) {
                    return (
                        <div className={s.colDurWrap}>
                            <Popover
                                content={(
                                    <div className={s.toolWrap}>
                                        This toggle is disabled because no practice address in this state is listed on your UniProfile. To enable in-person services, please add a practice address under UniProfile/Practice and update your services accordingly.
                                    </div>
                                )}
                            >
                                <Switch
                                    disabled={(!allowAddressOptions || allowAddressOptions.length === 0) && !isRemoveAddressButChecked}
                                    checked={(check && !showTips) || isRemoveAddressButChecked}
                                    onChange={(e) => {
                                        onInPersonChange(item, e);
                                    }}
                                />
                            </Popover>
                        </div>
                    );
                }
                return (
                    <div className={s.colDurWrap}>
                        <Switch
                            disabled={(!allowAddressOptions || allowAddressOptions.length === 0) && !isRemoveAddressButChecked}
                            checked={(check && !showTips) || isRemoveAddressButChecked}
                            onChange={(e) => {
                                onInPersonChange(item, e);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            title: (
                <div className={s.tableTitle}>
                    <span>PRACTICE ADDRESS</span>
                    <Popover placement="topLeft" content={<div className={s.toolWrap}>Your practice addresses are managed in your UniProfile/Practice address. If you need to add a new address, please update there and come back to this step.</div>} trigger="hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Popover>
                </div>
            ),
            render: (val, item: ServerMeta) => {
                const addressIds = stateAddressIdsMap[item.content] || [];
                const showAddressSelect = (stateContactTypeMap[item.content] || []).includes(EContactType.IN_PERSON);
                const allowAddressOptions = addressOptions?.filter((add: PracitceAddressForm) => {
                    return (add.practiceState?.toLowerCase() === item.dictKey.toLowerCase() || add.practiceState?.toLowerCase() === item.content.toLowerCase()) && [AddressPublicType.INVITATION, AddressPublicType.WALK_IN].includes(add.publicType);
                });
                return (
                    <div className={s.addWrap}>
                        {
                            showAddressSelect &&
                            <Select
                                mode="multiple"
                                value={addressIds}
                                style={{ width: '90%' }}
                                onChange={(ids) => {
                                    onAddressChange(item, ids);
                                }}
                                maxTagCount="responsive"
                                maxTagPlaceholder={(omittedValues) => (
                                    <Popover
                                        content={(
                                            <div className={s.toolWrap}>
                                                {
                                                    omittedValues.map(({ label }) => {
                                                        return <div key={label as string}>{label}</div>;
                                                    })}
                                            </div>)}
                                    >
                                        <span>+ {omittedValues.length} ...</span>
                                    </Popover>
                                )}
                            >
                                {
                                    allowAddressOptions.map((add) => {
                                        return (
                                            <Select.Option key={`${add.id}`} value={`${add.id}`}>
                                                {add.practiceAddress} ,{add.practiceCity}, {add.practiceState}, {add.practiceZip}
                                            </Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        }
                    </div>
                );
            },
        },
    ];
    return (
        <div className={s.wrap}>
            <Collapse
                expandIcon={(pop) => {
                    return (
                        <div className={`${s.popWrap} ${pop.isActive ? s.rotate : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M19.5 9.96875L12.5 16.9688L5.5 9.96875" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    );
                }}
                activeKey={isDisabled ? [] : activeKey}
                ghost
                expandIconPosition="end"
                onChange={(key: string[]) => {
                    setActiveKey(key.filter((k) => k !== ''));
                }}
            >
                <Collapse.Panel
                    header={
                        <div className={s.header}>
                            <span>{displayName}</span>
                            {
                                isAdd &&
                                <Button
                                    onClick={onRemove}
                                >
                                    Remove
                                </Button>
                            }
                            {
                                !isAdd && serviceTypeTemplate?.mandatory === false &&
                                <span className={s.mandatory}>
                                    <Switch
                                        checked={!isDisabled}
                                        onChange={
                                            (e) => {
                                                setIsDisabled(!e);
                                                onDisabled?.(!e);
                                            }
                                        }
                                    />
                                </span>
                            }
                        </div>
                    }
                    key={serviceTypeTemplate?.serviceType as string}
                >
                    <div className={s.content}>
                        <div className={s.item}>
                            <div
                                className={s.title}>
                                1. Applied states
                            </div>
                            <div className={s.des}>
                                By deselecting states here, you won't need to set prices or contact types for them, and they'll be removed from this service type's setup in subsequent sections
                            </div>
                            <div className={s.setStateWrap}>
                                <Select
                                    mode="multiple"
                                    style={{ width: '360px' }}
                                    value={states}
                                    onChange={(contents) => {
                                        if (!contents || contents.length === 0) {
                                            return;
                                        }
                                        setStates(contents);
                                        if (isInit && onInitVisitStateChange) {
                                            onInitVisitStateChange((allStates as ServerMeta[])?.filter((item) => contents.includes(item.content)) || []);
                                        }
                                    }}
                                    maxTagCount="responsive"
                                    disabled={!isEdit && serviceTypeTemplate?.serviceType === EServiceType.INIT}
                                // tagRender={(pop) => {
                                //     const target = allowStates?.find((item) => item.dictKey === pop.value);
                                //     return <span className={s.displayLabel}>{target ? target.content : pop.value}</span>;
                                // }}
                                >
                                    {
                                        statesOption?.map((ste) => {
                                            return (
                                                <Select.Option
                                                    disabled={isInit && initStatesFromEdit.includes(ste.content)}
                                                    key={ste.content} value={ste.content}>
                                                    {ste.content}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.title}>
                                2. Name and description
                            </div>
                            {
                                serviceType === EServiceType.OTHER_PATIENT_SUPPORT &&
                                <div className={s.otherTips}>
                                    <div className={s.left}>
                                        💡
                                    </div>
                                    <div className={s.right}>
                                        You can offer a range of services including documentation or paperwork support, lab test prescriptions, and in-depth research for referrals. Additionally, consider providing webinars, courses, and educational materials to further support and educate your patients.
                                    </div>
                                </div>
                            }
                            <div className={s.sub1}>
                                Service name
                            </div>
                            {
                                hasNameTips &&
                                <div className={s.nameTips}>
                                    <div className={s.left}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z" fill="#FBBF24" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z" fill="#FBBF24" />
                                        </svg>
                                    </div>
                                    <div className={s.right}>
                                        {targetNameTips.reminder}
                                    </div>
                                </div>
                            }
                            <div className={`${s.error} ${(nameIsExistInServerError || nameExistInEditing) ? s.errorShow : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.58579 10L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L10 11.4142L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L11.4142 10L12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L10 8.58579L8.70711 7.29289Z" fill="#F87171" />
                                </svg>
                                <span>This service name already exists. Please customize the name to distinguish this {displayName} from others.</span>
                            </div>
                            <div className={s.name}>
                                <span>
                                    {service?.specialtyName}
                                </span>
                                <Input
                                    placeholder="Optional for your customized input"
                                    className={s.input}
                                    maxLength={50}
                                    size="small"
                                    value={pendingCustomizeName}
                                    onChange={(e) => {
                                        setPendingCustomizeName(e.target.value);
                                    }}
                                    onBlur={() => {
                                        checkPendingServiceTypeName();
                                    }}
                                />
                                {
                                    hasNameTips &&
                                    <Popover placement="bottomLeft" content={<div className={s.toolWrap}>{targetNameTips.toolTips}</div>} trigger="hover">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Popover>
                                }
                                <span style={{ marginLeft: '6px' }}>: {displayName} [States] </span>
                            </div>
                            <div className={s.sub2}>
                                Example: {service?.specialtyName} <span className={s.hight}>{pendingCustomizeName}</span>: {displayName} [California]
                            </div>
                            <div className={s.desInputWrap}>
                                <div className={s.sub1}>
                                    Description
                                </div>
                                <Input.TextArea
                                    rows={5}
                                    maxLength={500}
                                    className={s.desInput}
                                    onChange={(e) => {
                                        setPendingDescription(e.target.value);
                                    }}
                                    value={pendingDescription}
                                />
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.title}>
                                3. Price and duration - Ava helped you to set up the competitive price in the markets.
                            </div>
                            <div className={s.tableWrap}>
                                <CommonTable
                                    bordered
                                    rowKey="dictKey"
                                    columns={klarityServicePriceDataColumns}
                                    data={
                                        states.map((st) => {
                                            return (allStates as ServerMeta[])?.find((item) => {
                                                return item.content === st || item.dictKey === st;
                                            });
                                        },
                                        ) as ServerMeta[]
                                    }
                                    pagination={false}
                                />
                            </div>
                            {
                                canEditPrice &&
                                <div className={s.tableTips}>
                                    *Prices can only be updated every 30 days. We recommend starting your services with the competitive prices calculated by <span className={s.ava}><img src={AvaIcon} /></span>.
                                </div>
                            }
                            {
                                !canEditPrice &&
                                <div className={s.tableTips}>
                                    *Prices can not be updated till <b>{targetDate}</b>
                                </div>
                            }
                            {
                                showConsultationFree &&
                                <div className={s.consWrap}>
                                    <div className={s.conLeft}>
                                        💡
                                    </div>
                                    <div className={s.conRight}>
                                        Offering free consultations is a great way to connect with new patients and build trust. Klarity requires that consultations be offered free of charge, but you can turn off this service using the toggle in the top right corner if needed.
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            serviceTypeTemplate?.switchTeleHealthInPerson &&
                            <div className={s.item}>
                                <div className={s.title}>
                                    4. Contact type - Choose how you want to connect with your patients in this service
                                </div>
                                <div className={s.tableWrap}>
                                    <CommonTable
                                        bordered
                                        pagination={false}
                                        rowKey="dictKey"
                                        columns={contactDataColumns}
                                        data={
                                            states.map((st) => {
                                                return (allStates as ServerMeta[])?.find((item) => {
                                                    return item.content === st || item.dictKey === st;
                                                });
                                            },
                                            ) as ServerMeta[]
                                        }
                                    />
                                </div>
                            </div>
                        }

                        {
                            serviceTypeTemplate?.switchPatientGroupLimitation &&
                            <div className={s.item}>
                                <div className={s.title}>
                                    <span>{serviceTypeTemplate?.switchTeleHealthInPerson === false ? '4' : '5'}. Patient group and limitations</span>
                                    <span
                                        onClick={() => {
                                            setPgiIsOpen(!pgiIsOpen);
                                        }}
                                        className={s.switch}
                                    >
                                        {
                                            pgiIsOpen ? <span>Hide patient group and limitations</span> : <span>Show patient group and limitations</span>

                                        }
                                        <span className={`${s.icon} ${pgiIsOpen && s.iconRotate}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.79289 8.26164C6.18342 7.87112 6.81658 7.87112 7.2071 8.26164L10.5 11.5545L13.7929 8.26164C14.1834 7.87112 14.8166 7.87112 15.2071 8.26164C15.5976 8.65217 15.5976 9.28533 15.2071 9.67586L11.2071 13.6759C10.8166 14.0664 10.1834 14.0664 9.79289 13.6759L5.79289 9.67586C5.40237 9.28533 5.40237 8.65217 5.79289 8.26164Z" fill="#00816B" />
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                                <div className={`${s.pglWrap} ${pgiIsOpen ? s.pglWrapOpen : ''}`}>
                                    {
                                        pendingPgiInfo?.map((pgi: PigInfo, inx) => {
                                            const allPgiLmitOption = [...pgiLmitOption, ...pgiLmitExtraOption];
                                            const setedState: string[] = [];
                                            const hasSelectedState = pgi.states && pgi.states.length > 0;
                                            pendingPgiInfo.forEach((info: PigInfo, inxP) => {
                                                if (inxP === inx) {
                                                    return;
                                                }
                                                info.states?.forEach((st) => {
                                                    if (!setedState.includes(st.content)) {
                                                        setedState.push(st.content);
                                                    }
                                                });
                                            });
                                            const statesOption = allowStates?.filter((item) => {
                                                if (setedState && setedState.length > 0) {
                                                    const find = setedState.find((st: string) => {
                                                        return st === item.content || st === item.dictKey;
                                                    });
                                                    if (find) {
                                                        return false;
                                                    }
                                                }
                                                return states.includes(item.content) || states.includes(item.dictKey);
                                            });
                                            return (
                                                <div className={s.pigItemWrap} key={`${inx}`}>
                                                    <div className={s.pigStateWrap}>
                                                        <div className={s.left}>
                                                            <div className={s.leftIn}>
                                                                <div className={s.label}>Applied states:</div>
                                                                <div className={s.pigInputWrap}>
                                                                    <Select
                                                                        mode="multiple"
                                                                        value={pgi.states?.map((st) => st.content)}
                                                                        style={{ width: '360px' }}
                                                                        onChange={(stateContents: string[]) => {
                                                                            const _states = allowStates?.filter((st) => {
                                                                                return stateContents?.includes(st.content) || stateContents?.includes(st.dictKey);
                                                                            });
                                                                            onPigStateChange(inx, _states || []);
                                                                        }}
                                                                        maxTagCount="responsive"
                                                                    // tagRender={(pop) => {
                                                                    //     const target = allowStates?.find((item) => item.dictKey === pop.value);
                                                                    //     return <span className={s.displayLabel}>{target ? target.content : pop.value}</span>;
                                                                    // }}
                                                                    >
                                                                        {
                                                                            statesOption?.map((ste) => {
                                                                                return (
                                                                                    <Select.Option key={ste.content} value={ste.content}>
                                                                                        {ste.content}
                                                                                    </Select.Option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                            {
                                                                !hasSelectedState &&
                                                                <div className={s.emptyState}>
                                                                    Removing all state restrictions allows bookings from all practice states, accepting patients without any limitations.
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            inx > 0 &&
                                                            <div
                                                                onClick={() => {
                                                                    const _pendingPgiInfo = [...pendingPgiInfo];
                                                                    _pendingPgiInfo.splice(inx, 1);
                                                                    setPendingPgiInfo(_pendingPgiInfo);
                                                                }}
                                                                className={s.right}
                                                            >
                                                                <Button>Remove</Button>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className={s.pigContactWrap}>
                                                        <div className={s.label}>Applied contact type:</div>
                                                        <div className={s.pigInputWrap}>
                                                            <Checkbox.Group
                                                                disabled={!hasSelectedState}
                                                                onChange={(val) => {
                                                                    onPigContactTypeChange(inx, val as EContactType[]);
                                                                }}
                                                                value={pgi.contactType}
                                                                options={contactTypeOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={s.subText}>
                                                        Patient accepted
                                                    </div>
                                                    <div className={s.accept}>
                                                        <Checkbox.Group
                                                            disabled={!hasSelectedState}
                                                            onChange={(val) => {
                                                                if (!val) {
                                                                    onPigAcceptChange(inx, []);
                                                                    return;
                                                                }
                                                                const cut = pgi.accept;
                                                                if (!cut?.includes('all') && val?.includes('all')) {
                                                                    onPigAcceptChange(inx, [...pgiAcceptOption.map((str) => str.value), 'all']);
                                                                    return;
                                                                }
                                                                if (cut?.includes('all') && !val?.includes('all')) {
                                                                    //clear all
                                                                    onPigAcceptChange(inx, []);
                                                                    return;
                                                                }
                                                                if (val.length === pgiAcceptOption.length && !val.includes('all')) {
                                                                    onPigAcceptChange(inx, [...(val as string[]), 'all']);
                                                                    return;
                                                                }
                                                                if (val.includes('all')) {
                                                                    onPigAcceptChange(inx, (val as string[]).filter((str) => str !== 'all'));
                                                                    return;
                                                                }
                                                                onPigAcceptChange(inx, val as string[]);
                                                            }}
                                                            value={pgi.accept}
                                                            className={s.checkAccpet}
                                                        >
                                                            <div className={s.rowWrap}>
                                                                {
                                                                    updatedPgiAcceptOption?.map((str) => {
                                                                        return (
                                                                            <div className={s.colWrap} key={str.label}>
                                                                                <Checkbox value={str.value} key={str.value}>{str.label}</Checkbox>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </Checkbox.Group>
                                                    </div>

                                                    <div className={s.subText}>
                                                        <span>Limitations</span>
                                                        <Popover placement="topLeft" content={<div className={s.toolWrap}>The limitation you set here will be displayed on your profile to support better patient matching</div>} trigger="hover">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </Popover>
                                                    </div>
                                                    <div className={s.accept}>
                                                        <Checkbox.Group
                                                            onChange={(val) => {
                                                                onPigLimitChange(inx, val as string[]);
                                                            }}
                                                            value={pgi.limitations}
                                                            className={s.checkAccpet}
                                                            disabled={!hasSelectedState}
                                                        >
                                                            <div className={s.limitWrap}>
                                                                {
                                                                    allPgiLmitOption?.map((str) => {
                                                                        return (
                                                                            <div key={str.label}>
                                                                                <Checkbox value={str.value} key={str.value}>{str.label}</Checkbox>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </Checkbox.Group>
                                                    </div>
                                                    {
                                                        pgi.isAddingLimit && hasSelectedState &&
                                                        <div className={s.extraLimitWrap}>
                                                            <Input
                                                                value={pgi.pendingNewLimit}
                                                                onChange={(e) => {
                                                                    onPigNewLimitChange(inx, e.target.value);
                                                                }}
                                                                placeholder="Add a limitation"
                                                                maxLength={100}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    onPigNewLimitChangeConfirm(inx);
                                                                }}
                                                                className={s.btn}
                                                                type="primary"
                                                                disabled={!pgi.pendingNewLimit}
                                                            >Add
                                                            </Button>
                                                            <Button className={s.btn}>Cancel</Button>
                                                        </div>
                                                    }
                                                    {
                                                        !pgi.isAddingLimit && hasSelectedState &&
                                                        <div
                                                            onClick={() => {
                                                                onPigIsAddingLimitChange(inx, true);
                                                            }}
                                                            className={s.extraLimitButton}
                                                        >
                                                            + Add a limitation
                                                        </div>
                                                    }

                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        allHasSelectState && !allStateSeted &&
                                        <div
                                            onClick={() => {
                                                const patientGroupIds = serviceTypeTemplateValue?.patientGroupIds?.split(',') || [];
                                                const limitationIds = serviceTypeTemplateValue?.limitationIds?.split(',') || [];
                                                const accept: string[] = serviceTypeTemplate?.klarityServiceTypePatientGroupList?.filter((i) => {
                                                    return patientGroupIds.includes(`${i.id}`);
                                                })?.map((i) => i.patientGroup) || [];
                                                const limitations: string[] = serviceTypeTemplate?.klarityServiceTypeLimitationList?.filter((i) => {
                                                    return limitationIds.includes(`${i.id}`);
                                                })?.map((i) => i.limitation) || [];
                                                const _pendingPgiInfo: PigInfo[] = [...pendingPgiInfo, {
                                                    accept: accept || [],
                                                    limitations: limitations || [],
                                                }];
                                                setPendingPgiInfo(_pendingPgiInfo);
                                            }}
                                            className={s.addPgi}
                                        >
                                            + Add patient group and limitations for other states
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default ConfigServiceType;
