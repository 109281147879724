import React from 'react';
import s from './s.module.less';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';

interface IProps {
    onSelect: (value: string) => void;
}

const DescrbeList = ({
    onSelect,
}: IProps) => {
    const [describeData] = useGetSettingData(EnumFields.TREATMENT_APPROACH);

    return (
        <div className={s.describeList}>
            {
                describeData?.map((describe, index) => {
                    return <div key={index} className={s.describe} onClick={() => onSelect(describe.content)}>{describe.content}</div>;
                })
            }
        </div>
    );
};

export default DescrbeList;
