import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { PracticeFormFieldEnum, PracticeFormInterface } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Modal } from 'antd';
import RichText from 'components/RichText';
import { TreatmentPhilosophyTextLength, EMPTY_PLACEHOLDER } from 'constants/common';
import { removeHtmlTag } from 'utils/common';
import SparklesIcon from 'assets/common/sparkles.png';
import TreatmentApproach from '../TreatmentApproch';
import { renderChangUI } from 'types/changeHighLight';

interface IProps {
    formName: string;
    showEditButtons?: boolean;
    initFormValue: Partial<PracticeFormInterface>,
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: {
        providerPractice: Partial<PracticeFormInterface>
    }) => void;
    isOps?: boolean;
}

const PracticeTreatment = forwardRef(({
    initFormValue,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
    isOps,
}: IProps, ref) => {
    const templateText = useRef('* Delete all gray text and replace it with your own treatment philosophy <br/><strong>I believe that</strong> [talk about what you believe in treating your patients]. <strong>My goal is to</strong> [talk about your goals, what you try to accomplish/achieve and how would you help the patients]. [A final summary of your treatment philosophy and the results you want to achieve].');
    const [showTreatmentPhilosophyTemplateModal, setShowTreatmentPhilosophyTemplateModal] = useState(false);
    const [TreatmentForm] = Form.useForm<PracticeFormInterface>();

    const {
        treatmentPhilosophy,
        treatmentApproach,
    } = initFormValue || {};

    useEffect(() => {
        if (initFormValue) {
            TreatmentForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, TreatmentForm]);

    const handleFormFinish = useCallback(async () => {
        TreatmentForm.validateFields().then((values: Partial<PracticeFormInterface>) => {
            if (typeof onUpdateService === 'function') {
                onUpdateService(formName, {
                    providerPractice: {
                        ...initFormValue,
                        ...values,
                    },
                });
            }
        }).catch((e) => {
            console.error(e);
        });
        // onUpdateService(formName, formValue);
        // setSubmitData(formValue);
    }, [formName, initFormValue, onUpdateService, TreatmentForm]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        try {
            validRes = await TreatmentForm.validateFields();
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [TreatmentForm]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));

    const showTemplateModal = useCallback(() => setShowTreatmentPhilosophyTemplateModal(true), []);
    const hideTemplateModal = useCallback(() => setShowTreatmentPhilosophyTemplateModal(false), []);

    const handleUseTemplate = useCallback(() => {
        TreatmentForm.setFieldsValue({ treatmentPhilosophy: templateText.current });
        hideTemplateModal();
    }, [hideTemplateModal, TreatmentForm]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [enable, formName, onEditStatusChange, showEditButtons, handleFormFinish]);

    const renderReviewTreatmentApproach = useMemo(() => {
        return treatmentApproach?.split('•').filter((e) => !!e).map((text, index) => <li key={index}>{text}</li>);
    }, [treatmentApproach]);

    return (
        <div className={s.wrap}>
            <Modal
                title="Treatment philosophy template"
                centered
                open={showTreatmentPhilosophyTemplateModal}
                okText="Use this template"
                onOk={handleUseTemplate}
                onCancel={hideTemplateModal}
                width={543}
            >
                <p>I believe that each individual is unique and deserves to be treated as such. My goal is to support you in taking the time to truly understand yourself, your strengths and challenges so that you can make informed decisions about the direction of your life. I’m not interested in throwing a bunch of pills at you and calling it a day, but I want to help you understand what’s going on with your body and mind so that we can come up with some strategies that work for you. I want to help my patients find a way of being in a world based on self-compassion and kindness.</p>
            </Modal>
            <h4>
                Treatment methodology
                {renderEditButtons}
            </h4>
            <div className={s.content}>
                {
                    !enable ?
                        <>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Treatment philosophy</div>
                                    {
                                        treatmentPhilosophy ?
                                            <div
                                                className={s.value}
                                                dangerouslySetInnerHTML={{
                                                    __html: treatmentPhilosophy || '',
                                                }}
                                            /> :
                                            EMPTY_PLACEHOLDER
                                    }
                                    {renderChangUI('treatmentPhilosophy', initFormValue, !!isOps,
                                        (val) => {
                                            return (
                                                <div
                                                    className={s.value}
                                                    dangerouslySetInnerHTML={{
                                                        __html: val || '',
                                                    }}
                                                    style={{ color: 'red' }}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Treatment approach</div>
                                    <div className={s.value}>
                                        { treatmentApproach ? <ul>{renderReviewTreatmentApproach}</ul> : EMPTY_PLACEHOLDER}
                                    </div>
                                    {renderChangUI('treatmentApproach', initFormValue, !!isOps,
                                        (val:string) => {
                                            return (
                                                <div className={s.value}>
                                                    <ul>
                                                        {val?.split('•').filter((e) => !!e).map((text, index) => <li style={{ color: 'red', listStyle: 'disc' }} key={index}>{text}</li>)}
                                                    </ul>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        </> :
                        <Form
                            form={TreatmentForm}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                    style={{ paddingRight: '96px' }}
                                >
                                    <div className={s.formItemLabel}>Treatment philosophy</div>
                                    <div className={s.subLabel}>Write a few sentences about how you treat patients, and what values to you the most as a provider</div>
                                    <div className={`${commonS.tips} ${s.tips}`}>
                                        <img src={SparklesIcon} />
                                        <p>It’s important for patients to know what the providers believe in and how they will treat patients before committing to a specific one. <span onClick={showTemplateModal}>See template</span></p>
                                    </div>
                                    <Form.Item
                                        // label="Treatment philosophy"
                                        name={PracticeFormFieldEnum.TREATMENT_PHILOSOPHY}
                                        rules={[
                                            {
                                                validator: (rule, value) => {
                                                    if (removeHtmlTag(value).length > TreatmentPhilosophyTextLength) {
                                                        return Promise.reject(`Maximum ${TreatmentPhilosophyTextLength} characters`);
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <RichText className={s.textarea} maxLength={TreatmentPhilosophyTextLength} placeholder="Ex. How do you treat patients? What values to you the most as a provider? What do you believe in?" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                    style={{ paddingRight: '96px' }}
                                >
                                    <div className={s.formItemLabel}>Treatment approach</div>
                                    <div className={s.des}>Select or describe the methodologies you use to treat patients. Content will be displayed in a bullet point format.</div>
                                    <Form.Item
                                        // label="Treatment approach"
                                        name={PracticeFormFieldEnum.TREATMENT_APPROACH}
                                    >
                                        <TreatmentApproach />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                }
            </div>
        </div>
    );
});

export default PracticeTreatment;
