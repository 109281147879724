import { ServerKlarityService } from 'types/common';

export enum EModalMode {
    SELECT_SERVICE = 'SELECT_SERVICE',
    CONFIG_SERVICE = 'CONFIG_SERVICE',
}

export type ServerData = {
    providerKlarityService:ServerKlarityService[],
    disableIds:number[],
    allSeted:boolean,
};
