/* eslint-disable react/no-unescaped-entities */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Modal, Radio, RadioChangeEvent, Space, message } from 'antd';
import Profile from '../Profile';
import Services from '../Services';
import Practice from '../Practice';
import HealthcareExperience from '../HealthcareExperience';
import Education from '../Education';
import AdditionalInformation from '../AdditionalInformation';
import channelProviderStore, { initApplicationFormData } from 'store/ChannelApplicationProvider';
import s from './s.module.less';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import { submitPreview } from 'api/applicationForm';
import { useNavigate } from 'react-router-dom';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, CHANNEL_DETAIL, PRIVACY_LINK, TERMS_LINK } from 'constants/common';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { NoticeType } from 'antd/es/message/interface';
import { IProfileFormSubmit } from 'types/channelApplicationProvider';
import Insurance from '../Insurance';
// import SingleServiceTypeChannelModal from 'components/SingleServiceTypeChannelModal';
// import useService from 'hooks/useService';
// import { Service } from 'types/common';
// import useSubmitRecommendChannels from 'hooks/useSubmitRecommendChannels';
import License from '../License';
import { ServiceExt, UniprofileFormData } from 'types/provider';
import Biographic from '../Biographic';
import { PATH } from 'constants/path';
import uniprofileResumeStore from 'store/UniprofileResume';
import { TUniprofileFlag } from 'types/common';
import KlarityServices from '../KlarityServices';
import { getUrlParam } from 'utils/common';

type ISubmitData = Partial<IProfileFormSubmit>;
interface IProps {
    isAva?: boolean;
    from?: string;
    showAuthModal?: boolean;
    channelId: number;
    service: ServiceExt[];
    onSubmit: (value: ISubmitData, step: StepValue) => void;
    onShowAuthModal?: (show: boolean) => void;
    refetch: () => void;
    onShowMessage: (type: NoticeType, content: string) => void;
    uniProfileFlag?: TUniprofileFlag;
}

const Preview = forwardRef(({
    isAva,
    service,
    from,
    channelId,
    showAuthModal,
    onSubmit,
    onShowAuthModal,
    refetch,
    onShowMessage,
    uniProfileFlag,
}: IProps, ref) => {
    const navigate = useNavigate();
    const [allowAuth, setAllowAuth] = useState<number>(1);
    const [editEnableMap, setEditEnableMap] = useState({
        [StepValue.PROFILE]: false,
        [StepValue.SERVICES]: false,
        [StepValue.PRACTICE]: false,
        [StepValue.HEALTHCARE_EXPERIENCE]: false,
        [StepValue.EDUCATION]: false,
        [StepValue.ADDITIONAL_INFORMATION]: false,
        [StepValue.INSURANCE]: false,
        [StepValue.BIOGRAPHIC]: false,
        [StepValue.LICENSE]: false,
    });
    // const [open, setOpen] = useState(false);
    // const [singleServiceTypeIds, setSingleServiceTypeIds] = useState<Array<number>>([]);
    // 如果某个channel不允许重复，但是目前还没有重复service的时候。
    // const [submitWithoutSingleServiceSelect, setSubmitWithoutSingleServiceSelect] = useState(false);

    const [getProvider] = channelProviderStore.useStore();
    const [getUniprofileResume] = uniprofileResumeStore.useStore();
    const uniprofileResumeData = getUniprofileResume('data');
    const uniprofuleCreationData = getProvider('data');
    const [formData, setFormData] = useState<UniprofileFormData>(initApplicationFormData);
    const { profile, services, practice, experienceList, educationList, addtional, insurance, licenses, physicians, biographic, malpracticeInsuranceCertificate } = formData as UniprofileFormData;
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    // const [allServices] = useService();
    // const [otherServiceList, setOtherServiceList] = useState<Array<Service>>([]);
    const [servicesData, setServicesData] = useState<ServiceExt[]>([]);
    const initPracticeName = useMemo(
        () => `${profile.firstName} ${profile.lastName}'s Practice`, [profile]);

    const scroll = getUrlParam('scroll');

    useEffect(() => {
        if (scroll && profile.email) {
            const div = document.getElementById(scroll);
            if (div) {
                setTimeout(() => {
                    div.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 500)

            }
        }
    }, [scroll, profile.email]);

    useEffect(() => {
        if (service && service.length > 0) {
            setServicesData(service);
        } else {
            setServicesData(services);
        }
    }, [service, services]);

    useEffect(() => {
        if (isAva) {
            if (uniprofileResumeData) {
                setFormData(uniprofileResumeData);
            }
        } else if (uniprofuleCreationData) {
            setFormData(uniprofuleCreationData);
        }
    }, [getProvider, uniprofileResumeData, isAva, uniprofuleCreationData]);

    // const [submitRecommandChannels] = useSubmitRecommendChannels();

    // useEffect(() => {
    //     const singleServiceTypes = singleServiceTypeIds.map((id) => {
    //         const serv = services.find((e) => e.id === id);

    //         return serv?.type;
    //     });
    //     setOtherServiceList(allServices.filter((service) => !singleServiceTypes.includes(service.type)));
    // }, [allServices, services, singleServiceTypeIds]);

    const handleStepEditStatusChange = useCallback((status: boolean, step: StepValue, callback: () => void) => {
        const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');

        if (!globalFormsEditStatus || !status) {
            setEditEnableMap({
                ...editEnableMap,
                [step]: status,
            });
            callback?.();

            setGloalEditStatus('globalEditStatus', status);
        } else {
            onShowMessage('error', 'Please save your changes before proceeding!');
        }
    }, [editEnableMap, getGlobalEditStatus, onShowMessage, setGloalEditStatus]);

    const handleAuthChange = useCallback((e: RadioChangeEvent) => {
        const val = e.target.value;

        setAllowAuth(val ? 1 : 0);
    }, []);

    const navigateToChannelSuccessPage = useCallback(() => {
        sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);

        navigate('/channel-success');
    }, [navigate]);

    // const navigateToRecommendChannelsPage = useCallback(() => {
    //     sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);

    //     navigate('/recomend-channels');
    // }, [navigate]);

    const handleUniprofileSubmit = useCallback(async () => {
        try {
            const requestResult = await submitPreview({
                allowAuth: allowAuth === 1,
                channelId,
            });

            if (requestResult && !requestResult.error) {
                if (from === CHANNEL_DETAIL) {
                    navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${channelId}`, { replace: true });
                } else {
                    navigateToChannelSuccessPage();
                }
            } else {
                onShowMessage('error', requestResult.error);
            }
        } catch (e) {
            console.error(e);
        }
    }, [allowAuth, channelId, from, navigate, navigateToChannelSuccessPage, onShowMessage]);

    // const handleFinalSubmit = useCallback(async () => {
    //     let multServiceList = allServices;
    //     if (singleServiceTypeIds.length > 0) {
    //         const singleSerivce = singleServiceTypeIds.map((id) => {
    //             const service = allServices.find((serv) => serv.id === id) as Service;

    //             return service;
    //         });

    //         multServiceList = [...otherServiceList, ...singleSerivce];
    //     }

    //     const res = await submitRecommandChannels({
    //         channelIdList: [channelId],
    //         multServiceList,
    //     });

    //     if (!res?.error) {
    //         return true;
    //     } else {
    //         onShowMessage('error', res.error);
    //     }
    // }, [allServices, channelId, onShowMessage, otherServiceList, singleServiceTypeIds, submitRecommandChannels]);

    // const handleServiceIsSingle = useCallback(async () => {
    //     const channelDetailRes = await getChannelDetailById(channelId.toString());

    //     if (!channelDetailRes.error) {
    //         const { data } = channelDetailRes.data;

    //         if (data.serviceConfig === SINGLE_TYPE_SINGLE_SERVICE && singleServiceTypeIds.length === 0) {
    //             setOpen(true);
    //         } else {
    //             await handleFinalSubmit();

    //             return true;
    //         }
    //     } else {
    //         onShowMessage('error', channelDetailRes.error);
    //     }
    // }, [channelId, handleFinalSubmit, onShowMessage, singleServiceTypeIds.length]);

    /**
     * channel submit
     * 都需要判断当前channel是否允许重复service type
     * 每次需要获取下recommend channel list
     * 1. 如果recommend channels为空，则提交结束
     * 2. 如果 recommend channel list只有1个，
     *   a. 若果recommend channel就是当前的channel，则不需要弹
     *   b. 如果不是，则还是需要跳去recommend channel page
     * 否则有1个以上，则必须跳去recommend channel page
     */
    // const handleChannelConfirmSubmit = useCallback(async () => {
    //     // 先处理自己的提交
    //     const isSubmitted = await handleServiceIsSingle();

    //     if (isSubmitted) {
    //         // 然后确定是否需要跳去recommend channel page
    //     // 如果 recommend channel 为空
    //         if (isNonRecommandChannels) {
    //             navigateToChannelSuccessPage();
    //         } else if (recommendChannelsData.length === 1 && recommendChannelsData[0].id === channelId) {
    //             // 如果recommend channel 只有一个，且是当前的channel，直接提交
    //             navigateToChannelSuccessPage();
    //         } else {
    //             navigateToRecommendChannelsPage();
    //         }
    //     }
    // }, [channelId, handleServiceIsSingle, isNonRecommandChannels, navigateToChannelSuccessPage, navigateToRecommendChannelsPage, recommendChannelsData]);

    const handleConfirmSubmit = useCallback(async () => {
        // const type = getUrlParam('type');

        // if (type === 'uniprofile') {
        handleUniprofileSubmit();
        // } else {
        //     handleChannelConfirmSubmit();
        // }
    }, [handleUniprofileSubmit]);

    const handleCancelSumbit = useCallback(() => {
        if (typeof onShowAuthModal === 'function') {
            onShowAuthModal(false);
        }
    }, [onShowAuthModal]);

    // const handleSingleServiceTypeSelected = useCallback((ids: Array<number>) => {
    //     setSingleServiceTypeIds(ids);
    // }, []);

    // const handleServiceListFeedback = useCallback((status: boolean) => {
    //     setSubmitWithoutSingleServiceSelect(status);
    // }, []);

    // const handleModalOk = useCallback(async () => {
    //     if (singleServiceTypeIds.length > 0 || submitWithoutSingleServiceSelect) {
    //         await handleFinalSubmit();
    //         setOpen(false);
    //         if (from === CHANNEL_DETAIL) {
    //             navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${channelId}`, { replace: true });
    //         } else {
    //             navigateToChannelSuccessPage();
    //         }
    //     } else {
    //         onShowMessage('error', 'Please select one from duplicate service-type');
    //     }
    // }, [channelId, from, handleFinalSubmit, navigate, navigateToChannelSuccessPage, onShowMessage, singleServiceTypeIds.length, submitWithoutSingleServiceSelect]);

    // const handleCloseModal = useCallback(() => {
    //     handleCancelSumbit();
    //     setOpen(false);
    // }, [handleCancelSumbit]);

    useImperativeHandle(ref, () => ({
        setStepEditStatus: handleStepEditStatusChange,
        handlePreviewConfirmSubmit: handleConfirmSubmit,
    }));

    return (
        <div>
            {/* <Modal
                centered
                open={open}
                onOk={handleModalOk}
                onCancel={handleCloseModal}
                width={1000}
            >
                <SingleServiceTypeChannelModal
                    logo="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/a5363e29-0a52-45ac-a4a1-6aa02d551367.png"
                    name=""
                    checkedIds={singleServiceTypeIds}
                    onServiceListFeedback={handleServiceListFeedback}
                    onChange={handleSingleServiceTypeSelected}
                />
            </Modal> */}
            <Modal
                title="Uniprofile"
                centered
                open={showAuthModal}
                okText="Confirm"
                onOk={handleConfirmSubmit}
                onCancel={handleCancelSumbit}
                width={543}
            >
                <div className={s.modalContent}>
                    <p className={s.modalContentText}>While reviewing your application, you may have missing/incorrect information. Does Kiwi Health's Provider Support team have the permission to edit/improve your profile? You can always edit your preferences later.</p>
                    <Radio.Group onChange={handleAuthChange} value={allowAuth}>
                        <Space direction="vertical">
                            <Radio value={1}>Yes, I authorize the Provider Support team to edit my profile</Radio>
                            <Radio value={0}>No, I prefer to edit my profile myself</Radio>
                        </Space>
                    </Radio.Group>
                    <p className={s.modalContentExp}>By clicking on the “Confirm” button, you agree to the Kiwi Health
                        <a style={{ color: '#4849ff' }} target="_blank" href={TERMS_LINK} rel="noreferrer"> terms </a>
                        and
                        <a style={{ color: '#4849ff' }} target="_blank" href={PRIVACY_LINK} rel="noreferrer"> privacy policy. </a>
                    </p>
                </div>
            </Modal>
            <div className={s.block}>
                <Profile showEditButtons editable={editEnableMap[StepValue.PROFILE]} initFormValue={profile} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <HealthcareExperience showEditButtons editable={editEnableMap[StepValue.HEALTHCARE_EXPERIENCE]} initFormValue={experienceList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <Education showEditButtons editable={editEnableMap[StepValue.EDUCATION]} initFormValue={educationList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <AdditionalInformation showEditButtons editable={editEnableMap[StepValue.ADDITIONAL_INFORMATION]} initFormValue={addtional} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <Practice showEditButtons editable={editEnableMap[StepValue.PRACTICE]} initFormValue={practice} initPracticeName={initPracticeName} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <License
                    editable={editEnableMap[StepValue.LICENSE]}
                    initLicenseFormValue={licenses}
                    initPhysicianFormValue={physicians}
                    initMalpracticeInsuranceCertificateValue={malpracticeInsuranceCertificate}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                    refetch={refetch}
                />
            </div>
            {
                uniProfileFlag?.kiwiServiceFlag &&
                <div className={s.block}>
                    <Services onShowMessage={onShowMessage} addressList={practice?.addressList} initFormValue={servicesData} refetch={refetch} />
                </div>
            }
            <div id='klarity-service' />
            {
                uniProfileFlag?.klarityServiceFlag &&
                <div className={s.block}>
                    <KlarityServices addressList={practice?.addressList} licenses={licenses} refetch={refetch} isReviewStep />
                </div>
            }

            <div className={s.block}>
                <Insurance showEditButtons editable={editEnableMap[StepValue.INSURANCE]} initFormValue={insurance} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <Biographic
                    showEditButtons
                    editable={editEnableMap[StepValue.BIOGRAPHIC]}
                    initFormValue={biographic}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                />
            </div>
        </div>
    );
});

export default Preview;
