import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Form, message } from 'antd';
import { EStep, PracticeFormInterface, StepValue } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import { IProfileFormSubmit } from 'types/channelApplicationProvider';
import Specialties from './component/SpecialtiesForm';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import PracticeFrom from './component/PracticeForm';
import PracticeAddress from './component/PracticeAddress';
import PracticeTreatment from './component/PracticeTreatment';

type FormValue = Partial<PracticeFormInterface> & { appointmentIntegrationUrl?: string, avaAutoReply?: boolean };

interface IProps {
    allStartEditing?: boolean;
    isOps?: boolean;
    initFormValue: FormValue;
    editable?: boolean;
    showEditButtons: boolean;
    initPracticeName: string;
    onSubmit: (value: Partial<IProfileFormSubmit>, step: StepValue) => void;
    // onNext?: (value: Partial<IProfileFormSubmit>) => void,
    onEditableChange?: (enable: boolean, step: StepValue, callback: () => void) => void;
    refetch: () => void;
}

const formNames = {
    SPECIALTIES: 'specialties',
    PRACTICE: 'practice',
    PRACTICE_ADD: 'practiceAddress',
    TREATMENT_METH: 'treatmentMeth',
};

const Practice = forwardRef(({
    // inProgress = false,
    allStartEditing = false,
    isOps,
    initFormValue,
    // editable = false,
    showEditButtons = true,
    initPracticeName,
    onSubmit,
    // onNext,
    // onEditableChange,
    refetch,
}: IProps, ref) => {
    const [practiceForm] = Form.useForm<FormValue>();
    const [formEditStatus, setFormEditStatus] = useState<Record<string, boolean>>({
        [formNames.SPECIALTIES]: allStartEditing,
        [formNames.PRACTICE]: allStartEditing,
        [formNames.PRACTICE_ADD]: allStartEditing,
        [formNames.TREATMENT_METH]: allStartEditing,
    });
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');
    const specialtiesRef = useRef();
    const practiceFromRef = useRef();
    const practiceAddressRef = useRef();
    const practiceTreatmentRef = useRef();

    useEffect(() => {
        if (initFormValue) {
            practiceForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, practiceForm]);

    const handleEditStatusChange = useCallback((v: boolean, formName: string) => {
        if (!globalFormsEditStatus || !v) {
            formEditStatus[formName] = v;

            setFormEditStatus({ ...formEditStatus });
            setGloalEditStatus('globalEditStatus', v);
        } else {
            message.error('Please save your changes before proceeding');
        }
    }, [formEditStatus, globalFormsEditStatus]);

    const handleSubmitRequest = useCallback(async (formName: string, formValue) => {
        const submitRes = await onSubmit(formValue, StepValue.PRACTICE);

        if (!submitRes?.error) {
            if (formName) {
                handleEditStatusChange(false, formName);
            } else {
                setGloalEditStatus('globalEditStatus', false);
            }

            refetch();
        } else {
            message.error('save failed!');
            console.error(submitRes);
        }

        return submitRes;
    }, [handleEditStatusChange, onSubmit, refetch]);

    const handleSubmitAllForms = useCallback(async () => {
        const specialtiesData = await specialtiesRef.current?.getFromValues();
        const practiceFromData = await practiceFromRef.current?.getFromValues();
        const practiceAddressData = await practiceAddressRef.current?.getFromValues();
        const practiceTreatmentData = await practiceTreatmentRef.current?.getFromValues();

        let submitRes = {
            error: true,
        };

        if (specialtiesData?.specialtyList && practiceFromData && practiceAddressData?.addressList && practiceTreatmentData) {
            if (
                (specialtiesData && !specialtiesData.errorFields) &&
                (practiceFromData && !practiceFromData.errorFields) &&
                (practiceAddressData && !practiceAddressData.errorFields) &&
                (practiceTreatmentData && !practiceTreatmentData.errorFields)
            ) {
                const practiData = {
                    ...initFormValue,
                    addressList: practiceAddressData?.addressList,
                    ...specialtiesData,
                    ...practiceFromData,
                    ...practiceTreatmentData,
                };

                submitRes = await handleSubmitRequest('', {
                    providerPractice: practiData,
                });
            }
        }

        return submitRes;
    }, [handleSubmitRequest, initFormValue]);

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmitAllForms,
    }));

    return (
        <div className={s.module}>
            <div className={s.wrap}>
                <Specialties
                    showEditButtons={showEditButtons}
                    ref={specialtiesRef}
                    isOps={isOps}
                    formName={formNames.SPECIALTIES}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.SPECIALTIES]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
                <PracticeFrom
                    showEditButtons={showEditButtons}
                    ref={practiceFromRef}
                    isOps={isOps}
                    formName={formNames.PRACTICE}
                    initFormValue={initFormValue}
                    initPracticeName={initPracticeName}
                    enable={formEditStatus[formNames.PRACTICE]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
                <PracticeAddress
                    isOps={isOps}
                    showEditButtons={showEditButtons}
                    ref={practiceAddressRef}
                    formName={formNames.PRACTICE_ADD}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.PRACTICE_ADD]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
                <PracticeTreatment
                    isOps={isOps}
                    showEditButtons={showEditButtons}
                    ref={practiceTreatmentRef}
                    formName={formNames.TREATMENT_METH}
                    initFormValue={initFormValue}
                    enable={formEditStatus[formNames.TREATMENT_METH]}
                    onEditStatusChange={handleEditStatusChange}
                    onUpdateService={handleSubmitRequest}
                />
            </div>
        </div>
    );
});

export default Practice;
