import { Request } from '../request';
import { ProviderBasicInfo } from 'types/provider';
import {
    GetOpsListInput,
    GetOpsDetailInput,
    SelectProviderChannelPhotoInput,
    GetProfileReviewListInput,
    ProviderReview,
    UpdateProviderChannelAuditStatusInput,
    ProviderChannelAuditGoLiveInput,
    GetOpsUniprofileInput,
    GetOpsUniprfoleDetailInput,
    UpdateUniprofileAuditStatusInput,
    UpdateOpsChannelWebsiteInput,
    UpdateAssigneeInput,
    UpdateNotesInput,
    GetOpsChannelWithdrawListInput,
    GetOpsChannelWithdrawDetailInput,
    UpdateOpsChannelWithdrawStatusInput,
    UpdateOpsChannelWithdrawNotesInput,
    UpdateOpsChannelWithdrawAssigneeInput,
    ISaveHelloKlarityBookingLinkInput,
    IGetProviderMappingDataInput,
    IUpdateProviderSlugInput,
    IUpdatePatientRatingInput,
    IUpdateHelloKlarityListingStatusInput,
    ISaveSpecialtiesHighlightInput,
    ISaveAirtableProviderInput,
    IUpdateHelloKlarityAirtableTitleInput,
    IUpdateHelloKlarityMappingStatusInput,
    IGenerateSaleLinkInput,
    IGetHelloKlarityPageManagementResp,
    TContentInfo,
    TFaqInfo,
    TInternalLink,
    EInternalLinkType,
    IGeAddOnResp,
} from 'types/operation';

import type { ServerMeta } from 'types/common';
import { ChannelItem, License, Physician, Service, IntakeqForm, EStatus, HomeConfig, EChannelActiveStatus, ServerKlarityService } from 'types/common';

import { TPage, TProviderPage } from 'types/kiwiHealth';
import { IServiceAddressUpdateInput } from 'types/applicationForm';
import { TFetchAiCtaResultRes } from 'types/ai';

import { type TKlarityPage, type TCarousel, type TConditionSetting, ECarouselsStatus, TPageManagement, IBatchUpdateBookingLinkInput } from 'types/helloKlarity';

import { ISubmitRecommandChannelInput } from 'types/channel';
import type { IGetSalesContractPageDataInput, TSalesInfo } from 'types/contractLink';
import { IUpdateKlarityServiceInput, IUpdateKlaritySpecialtyInput } from 'types/serviceTemplate';

type ProviderListFetchParam = {
    page: number,
    size: number
};

export const providerListFetch = (params: ProviderListFetchParam) =>
    Request.get({ url: '/admin-server/provider/list', params });

export const providerFetch = (id: number) =>
    Request.get({ url: `/admin-server/provider/get/${id}` });

export const opUpdateproviderBasicInfo = (data: Partial<ProviderBasicInfo>, id: number) =>
    Request.put<Partial<ProviderBasicInfo>>({ url: `/admin-server/provider/updatePersonInfo/${id}`, params: data, disableErrorNote: true });

export const providerUpdateGmail = (id: number, gmail: string) =>
    Request.put<{ gmail: string }>({ url: `/admin-server/provider/updateGmail/${id}`, params: { gmail } });

export const getProviderChannelAuditList = (data: GetOpsListInput) => Request.post({ url: '/admin-server/provider/channel/audit/list', params: data });

export const getChannelAuditDetail = (data: GetOpsDetailInput) => Request.post({ url: '/admin-server/provider/channel/audit/detail', params: data });

export const updateProviderChannelInfo = (data) => Request.put({ url: '/admin-server/provider/profile/update', params: data });

export const selectProviderChannelPhoto = (data: SelectProviderChannelPhotoInput) => Request.put({ url: '/admin-server/provider/profile/select/photo', params: data });

export const opLicenseUpdate = (data: Partial<License>, id: number) =>
    Request.put<Partial<License>>({ url: `/admin-server/provider/updateLicense/${id}`, params: data, disableErrorNote: true });

export const opPhysicianUpdate = (data: Partial<Physician>, id: number) =>
    Request.put<Partial<Physician>>({ url: `/admin-server/provider/savePhysicianByProviderId/${id}`, params: data, disableErrorNote: true });

export const getProviderChannelReviewList = (data: GetProfileReviewListInput) => Request.post({ url: '/admin-server/provider/channel/review/list', params: data });

export const addProviderChannelReviews = (data: Array<ProviderReview>) => Request.post({ url: '/admin-server/provider/channel/review/add', params: data });

export const updateProviderChannelAuditStatus = (data: UpdateProviderChannelAuditStatusInput) => Request.put({ url: '/admin-server/provider/channel/audit', params: data });

export const providerChannelAuditGoLive = (data: ProviderChannelAuditGoLiveInput) => Request.put({ url: '/admin-server/provider/channel/audit/golive', params: data });

export const serviceTemplateList = () => Request.get<Service[]>({ url: '/admin-server/serviceTemplate/list' });

export const serviceTemplateCreate = (data: Service) => Request.post({ url: '/admin-server/serviceTemplate/create', params: data });
export const serviceTemplateUpdate = (data: Service, id: number) =>
    Request.put<Service>({ url: `/admin-server/serviceTemplate/update/${id}`, params: data });

export const serviceTemplateDelete = (id: number) => Request.delete({ url: `/admin-server/serviceTemplate/del/${id}` });

export const getLiveChannelList = () => Request.get({ url: '/admin-server/channel/live/list' });

export const getUpcomingChannelList = () => Request.get({ url: '/admin-server/channel/upcoming/list' });

export const getInterestChannelList = () => Request.get({ url: '/admin-server/channel/interest/list' });

export const getChannelDetail = (id: number) =>
    Request.get({ url: `/admin-server/channel/detail/${id}` });

export const updateChannelDetail = (item: Partial<ChannelItem>) =>
    Request.post({ url: '/admin-server/channel/saveOrUpdate', params: item });

export const updateChannelGoLive = (id: number) =>
    Request.put({ url: `/admin-server/channel/upcoming/tolive/${id}` });

export const updateChannelTop = (id: number) =>
    Request.put({ url: `/admin-server/channel/top/${id}` });
export const updateChannelCancelTop = (id: number) =>
    Request.put({ url: `/admin-server/channel/cancelTop/${id}` });

// uniprofile
export const getUniprofileList = (data: GetOpsUniprofileInput) => Request.post({ url: '/admin-server/provider/profile/audit/list', params: data });

export const getUniprofileAuditDetail = (data: GetOpsUniprfoleDetailInput) => Request.post({ url: '/admin-server/provider/profile/audit/detail', params: data });

export const getUniprofileAuditSnapshot = (providerId: number) => Request.get({ url: `/admin-server/provider/profile/snapshot/latest/${providerId}` });

export const updateUniprofileAuditStatus = (data: UpdateUniprofileAuditStatusInput) => Request.put({ url: '/admin-server/provider/profile/audit', params: data });

export const exportExcel = (providerId: number) => Request.get({ url: '/admin-server/provider/profile/export', params: { providerId } });

export const channelWaitingApproval = (channelId: number, providerId: number) => Request.put({ url: '/admin-server/provider/channel/waitingapproval', params: { channelId, providerId } });

export const updateOpsChannelWebsite = (data: UpdateOpsChannelWebsiteInput) => Request.put({ url: '/admin-server/provider/channel/link/update', params: data });

export const getAdminIntakeqForm = () =>
    Request.get<IntakeqForm[]>({
        url: '/admin-server/intakeq/form/template/list',
        disableErrorNote: true,
    });

export const getOpsUserPermissions = () => Request.get({
    url: '/admin-server/api/auth/priv/list',
});

export const updateListToChannelStatusByIds = (ids: number[], status: EStatus) =>
    Request.put({ url: `/admin-server/provider/channel/${status}/status/update`, params: ids });

export const updateOpsAssignee = (data: UpdateAssigneeInput) => Request.put({ url: '/admin-server/provider/channel/assignee/update', params: data });

export const updateOpsNotes = (data: UpdateNotesInput) => Request.put({ url: '/admin-server/provider/channel/notes/update', params: data });

export const updateUniprofileAssignee = (data: UpdateAssigneeInput) => Request.put({ url: '/admin-server/provider/profile/assignee/update', params: data });

export const updateUniprofileNotes = (data: UpdateNotesInput) => Request.put({ url: '/admin-server/provider/profile/notes/update', params: data });

// export const createProviderService = (data: Service) => Request.post({ url: '/admin-server/providerService/create', params: data });
// export const updateProviderService = (data: Service, id:number) =>
//     Request.put<Service>({ url: `/admin-server/providerService/update/${id}`, params: data });

export const deleteProviderService = (id: number) => Request.delete({ url: `/admin-server/providerService/del/${id}` });

export const getSpecialtiesAndConditionList = () => Request.get({ url: '/admin-server/provider/profile/practice/specialties' });

export const updateGmailToProvider = (id: number, gmail: string, gmailPassword: string) =>
    Request.put({ url: `/admin-server/provider/updateGmail/${id}`, params: { gmail, gmailPassword } });

export const getHomeConfiglList = () => Request.get({ url: '/admin-server/homeConfig/list' });
export const homeConfigDelete = (id: number) => Request.delete({ url: `/admin-server/homeConfig/del/${id}` });
export const homeConfigGetById = (id: number | string) => Request.get({ url: `/admin-server/homeConfig/get/${id}` });
export const homeConfigCreate = (data: HomeConfig) => Request.post({ url: '/admin-server/homeConfig/create', params: data });
export const homeConfigUpdate = (data: HomeConfig, id: number) =>
    Request.put<HomeConfig>({ url: `/admin-server/homeConfig/update/${id}`, params: data });

// export const deleteProviderService = (id: number) => Request.delete({ url: `/admin-server/providerService/del/${id}` });

export const createProviderService = (data: Service) => Request.post({ url: '/admin-server/providerService/create', params: data });
export const updateProviderService = (data: Service, id: number) =>
    Request.put<Service>({ url: `/admin-server/providerService/update/${id}`, params: data });

export const getKiwiPageList = () => Request.get({ url: '/admin-server/pageInfo/list' });
export const kiwiPageDelete = (id: number) => Request.delete({ url: `/admin-server/pageInfo/del/${id}` });
export const kiwiPageCreate = (data: TPage) => Request.post({ url: '/admin-server/pageInfo/create', params: data });
export const kiwiPageUpdate = (data: TPage, id: number) =>
    Request.put<TPage>({ url: `/admin-server/pageInfo/update/${id}`, params: data });

type GetProviderPageInfoListParam = {
    current: number;
    nameCond?: string;
    size: number;
};
export const getProviderPageInfoList = (data: GetProviderPageInfoListParam) => Request.post({ url: '/admin-server/providerPageInfo/provider/list', params: data });
export const providerPageInfoUpdate = (data: TProviderPage[], providerId: number) =>
    Request.post<TProviderPage[]>({ url: `/admin-server/providerPageInfo/update/${providerId}`, params: data });

export const getRemovementPopupProviderPracticeAddressList = (providerId: number) => Request.get({ url: `/admin-server/provider/profile/practice/address/${providerId}/list` });

export const getAllServiceByPracticeAddressId = (providerId: number, addressId: number) => Request.get({ url: `/admin-server/providerService/list/${providerId}/${addressId}` });

export const updateProviderServiceAddress = (data: Array<IServiceAddressUpdateInput>) => Request.put({ url: '/admin-server/providerService/update/batch', params: data });

export const getProviderChannelwithdrawList = (data: GetOpsChannelWithdrawListInput) => Request.post({ url: '/admin-server/provider/channel/withdraw/list', params: data });

export const getProviderChannelwithdrawDetail = (data: GetOpsChannelWithdrawDetailInput) => Request.post({ url: '/admin-server/provider/channel/withdraw/detail', params: data });

export const updateOpsChannelWithdrawStatus = (data: UpdateOpsChannelWithdrawStatusInput) => Request.put({ url: '/admin-server/provider/channel/withdraw/status/update', params: data });

export const updateOpsChannelWithdrawNotes = (data: UpdateOpsChannelWithdrawNotesInput) => Request.put({ url: '/admin-server/provider/channel/withdraw/notes/update', params: data });

export const updateOpsChannelWithdrawAssignee = (data: UpdateOpsChannelWithdrawAssigneeInput) => Request.put({ url: '/admin-server/provider/channel/withdraw/assignee/update', params: data });

export const updateProviderStatusByIds = (providerIds: number[], status: EStatus) =>
    Request.put({ url: `/admin-server/provider/${status}/status/update`, params: providerIds });

export const updateProviderChannelStatusByIds = (providerIds: number[], status: EStatus) =>
    Request.put({ url: `/admin-server/provider/channel/${status}/status/update`, params: providerIds });

export const updateProviderSeoCity = (data: string[], providerId: number, state: string) =>
    Request.put({ url: '/admin-server/provider/seo/city/update', params: { seoCity: JSON.stringify(data), state, providerId } });

export const updateProviderSeoCountry = (data: string[], providerId: number, state: string) =>
    Request.put({ url: '/admin-server/provider/seo/country/update', params: { seoCountry: JSON.stringify(data), state, providerId } });

export const updateProviderSeoLandmark = (data: string[], providerId: number, state: string) =>
    Request.put({ url: '/admin-server/provider/seo/landmark/update', params: { seoLandmark: JSON.stringify(data), state, providerId } });

export const updateProviderSeoZip = (data: string[], providerId: number, state: string) =>
    Request.put({ url: '/admin-server/provider/seo/zip/update', params: { seoZip: JSON.stringify(data), state, providerId } });

export const getLocalSeoInfoByZipCodeFromOps = (zipCode: string) =>
    Request.get({ url: `/admin-server/provider/seo/google/${zipCode}` });

export const opsGetSuggestCityByInput = (input: string) =>
    Request.get({ url: `/admin-server/provider/seo/google/city/${input}` });

export const opsGetAiEditorConfig = () => Request.get({ url: '/admin-server/ai/config/editor' });

export const opsFetchAiCtaResult = (params: TFetchAiCtaResultRes) =>
    Request.post(
        { url: '/admin-server/ai/editor/rewrite', params, config: { timeout: 120 * 1000 } },
    );

// helloklarity ops
export const saveHelloKlarityBookingLink = (params: ISaveHelloKlarityBookingLinkInput) => Request.post({ url: '/admin-server/provider/mapping/link', params });

export const getHelloKlarityBookingLinkListByProviderId = (bpProviderId: number) => Request.get({ url: `/admin-server/provider/mapping/link/${bpProviderId}` });

export const deleteHelloKlarityBookingLinkById = (id: number) => Request.delete({ url: `/admin-server/provider/mapping/link/${id}` });

export const getHelloKlarityProviderMappingData = (params: IGetProviderMappingDataInput) => Request.post({ url: '/admin-server/provider/mapping/page', params });

export const updateHelloKlarityProviderSlug = (params: IUpdateProviderSlugInput) => Request.post({ url: '/admin-server/provider/mapping/update/slug', params });

export const updateHelloKlarityPatientRating = (params: IUpdatePatientRatingInput) => Request.post({ url: '/admin-server/provider/mapping/update/rating', params });

export const helloklarityProvderGoLive = (id: number) => Request.post({ url: '/admin-server/provider/mapping/go/live', params: { id } });

export const updateHelloKlarityListingStatus = (params: IUpdateHelloKlarityListingStatusInput) => Request.post({ url: '/admin-server/provider/mapping/update/listing/status', params });

export const saveSpecialtyHighlight = (params: ISaveSpecialtiesHighlightInput) => Request.post({ url: '/admin-server/provider/saveSpecialtyHighlight', params });

export const helloklarityMappingGolive = (id: number) => Request.post({ url: '/admin-server/provider/mapping/go/live', params: { id } });

export const getHelloKlairtyProfileLink = (id: number) => Request.get({ url: '/admin-server/provider/mapping/getProfileLink', params: { id } });

export const getAirtableDataByIntakeqProviderId = (intakeqProviderId: string) => Request.get({ url: '/admin-server/provider/mapping/airtable/fetch', params: { intakeqProviderId } });

export const updateHelloKlarityAirtableTitle = (params: IUpdateHelloKlarityAirtableTitleInput) => Request.post({ url: '/admin-server/provider/mapping/update/title', params });

export const updateHelloKlarityMappingStatus = (params: IUpdateHelloKlarityMappingStatusInput) => Request.post({ url: '/admin-server/provider/mapping/update/status', params });

export const addAirtableProvider = (params: ISaveAirtableProviderInput) => Request.post({ url: '/admin-server/provider/mapping/airtable/save', params });
export const getKlarityPageList = () => Request.get({ url: '/admin-server/klarity/page/list' });
export const getKlarityPageById = (id: string | number) => Request.get({ url: `/admin-server/klarity/page/get/${id}` });
export const klarityPageCreate = (data: TKlarityPage) => Request.post({ url: '/admin-server/klarity/page/create', params: data });
export const klarityPageUpdate = (data: TKlarityPage, id: number) =>
    Request.put<TKlarityPage>({ url: `/admin-server/klarity/page/update/${id}`, params: data });

export const klarityCarouselsCreate = (data: TCarousel) => Request.post({ url: '/admin-server/klarity/page/carousel/create', params: data });
export const klarityCarouselsDelete = (id: string | number) => Request.delete({ url: `/admin-server/klarity/page/carousel/del/${id}` });
export const getKlarityCarouselById = (id: number) => Request.get({ url: `/admin-server/klarity/page/carousel/get/${id}` });
export const getKlarityCarouselsList = (data: {
    pageId: number | string;
    status?: ECarouselsStatus
}) => Request.get({ url: '/admin-server/klarity/page/carousel/list', params: data });
export const getKlarityCarouselsListStat = (pageId: number) => Request.get({ url: `/admin-server/klarity/page/carousel/stat/${pageId}` });
export const klarityCarouselsExchange = (fromId: number | string, toId: number | string) =>
    Request.put<TCarousel>({ url: `/admin-server/klarity/page/carousel/priority/exchange/${fromId}/${toId}` });
export const klarityCarouselsUpdate = (data: TCarousel, id: number) =>
    Request.put<TCarousel>({ url: `/admin-server/klarity/page/carousel/update/${id}`, params: data });

export const getKlarityConditionList = () => Request.get({ url: '/admin-server/klarity/condition/list/HOME' });
export const getKlarityConditionById = (id: number) => Request.get({ url: `/admin-server/klarity/condition/get/${id}` });
export const klarityConditionUpdate = (data: TConditionSetting) => Request.post({ url: '/admin-server/klarity/condition/saveOrUpdate', params: data });

export const klarityGoLiveUpdate = (data: TPageManagement) => Request.post({ url: '/admin-server/klarity/page/carousel/golive', params: data });
export const klarityGetUpdateTime = (pageId: number | string) => Request.get({ url: `/admin-server/klarity/page/lastModify/${pageId}`, params: {} });

export const getAppointmentsByBookingLinks = (data: string[]) => Request.post({ url: '/admin-server/provider/mapping/ehrProviderService/review', params: data });

export const submitChannels = (data: ISubmitRecommandChannelInput) => Request.post({ url: '/admin-server/provider/channel/submit', params: data, disableErrorNote: true });

export const updateChannelVisible = (channelId: number, s: boolean) => Request.put({ url: `admin-server/channel/show/${channelId}/${s}` });

export const updateChannelActivation = (channelId: number, s: boolean) => {
    let status = EChannelActiveStatus.ACTIVE;
    if (!s) {
        status = EChannelActiveStatus.INACTIVE;
    }
    return Request.put({ url: `admin-server/channel/active/status/${channelId}/${status}` });
};

export const getSalesContractPage = (params: IGetSalesContractPageDataInput) => Request.post({ url: '/admin-server/sales/contract/link/page', params });

export const generateSalesContractLink = (params: IGenerateSaleLinkInput) => Request.post({ url: '/admin-server/sales/contract/link/generate', params });

export const getPayPlanList = () =>
    Request.get({ url: '/admin-server/payPlan/list' });

export const getOfferingList = (providerId: number) => Request.get({ url: `admin-server/provider/mapping/offering/list/${providerId}` });

export const getLicenseList = (providerId: number) => Request.get({ url: `admin-server/license/practice/license/list/${providerId}` });

export const getOpsUserInfo = (id: number) => Request.get({ url: `admin-server/user/get/${id}` });
export const getSalesInfo = (userId?: number) =>
    Request.get({ url: `/admin-server/user/get/${userId}` });

export const updateSalesInfo = (userId: number, params: Partial<TSalesInfo>) =>
    Request.put({ url: `/admin-server/user/update/${userId}`, params });

export const getViewPdf = (params: { contractSignUrl: string }) =>
    Request.get({ url: '/admin-server/sales/getViewPdf', params });

export const getProviderViewPdf = (params: { providerId: number }) =>
    Request.get({ url: `/admin-server/provider/contract/list/${params.providerId}` });

export const deleteLicense = (id: number) => Request.delete({ url: `/admin-server/license/del/${id}` });

export const deletePhysician = (id: number) => Request.delete({ url: `/admin-server/physician/del/${id}` });

// klarity service template
export const getKlarityServiceTypeList = () => Request.get({ url: '/admin-server/klarityServiceType/list' });

export const getKlaritySpecialtyList = () => Request.get({ url: '/admin-server/klarityServiceSpecialty/list' });

export const updateKlarityServiceType = (data: IUpdateKlarityServiceInput) => Request.put({ url: '/admin-server/klarityServiceType/update', params: data });

export const updateKlaritySpecial = (data: IUpdateKlaritySpecialtyInput) => Request.put({ url: '/admin-server/klarityServiceSpecialty/update', params: data });

export const getKiwiSpecialtiesByKlaritySpecialty = (specialty: string) => Request.get({ url: '/admin-server/klarityServiceSpecialty/specialityTemplateSettingSpecialty', params: { recommendedKlaritySpecialty: specialty } });

export const getOpsSpecialityTemplateRecommend = (providerId: number) =>
    Request.get({
        url: '/admin-server/klarityServiceSpecialty/specialityTemplateRecommend',
        params: { providerId },
    });

export const getOpsSpecialityPrice = (specialty: string, state: string[], providerId: number) =>
    Request.get(
        {
            url: '/admin-server/provider/profile/service/price/config',
            params: {
                specialty,
                state,
                providerId,
            },
        },
    );

export const checkOpsProviderKlarityService = (providerId: number) =>
    Request.get(
        {
            url: `/admin-server/provider/profile/service/check/${providerId}`,
        },
    );

export const saveOpsProviderKlarityService = (providerId: number, providerKlarityService: ServerKlarityService[], disableIds: number[]) => {
    const data: ServerKlarityService[] = [...(providerKlarityService || [])];
    data.forEach((item) => {
        item.providerId = providerId;
    });
    return Request.post(
        {
            url: '/admin-server/provider/klarity/service/save',
            params: {
                providerKlarityService: data,
                disableIds,
            },
        },
    );
};

export const checkOpsServiceTypeName = (providerId: number, data: { serviceSpecialtyId: number, customizedServiceName: string, serviceTypeId: number }) =>
    Request.post(
        {
            url: '/admin-server/provider/klarity/service/customized/name/check',
            params: {
                ...data,
            },
        },
    );

export const getOpsProviderKlarityServiceList = (providerId: number) =>
    Request.get(
        {
            url: `/admin-server/provider/klarity/service/list/${providerId}`,
        },
    );

export const getOpsProviderPatientGroupAndLimitation = (id: number) =>
    Request.get(
        {
            url: `/admin-server/provider/klarity/service/patientGroupAndLimitation/${id}`,
        },
    );

export const getOpsSpecialityTemplateDetail = (id: number) =>
    Request.get(
        {
            url: '/admin-server/klarityServiceSpecialty/specialityTemplateDetail',
            params: {
                id,
            },
        },
    );

export const getOpsProviderSpecialtyDetail = (serviceSpecialtyId: number, providerId: number) =>
    Request.get(
        {
            url: '/admin-server/provider/klarity/service/detail',
            params: {
                serviceSpecialtyId,
                providerId,
            },
        },
    );

export const syncSnapshot = (provider: number) =>
    Request.put({ url: `/admin-server/provider/profile/snapshot/sync/${provider}` });

export const getSpecialtiesNServiceType = () => Request.get({ url: '/admin-server/klarityServiceSpecialty/specialtyAndServiceType' });

export const batchUpdateBookinglinks = (data: IBatchUpdateBookingLinkInput[]) => Request.post({ url: '/admin-server/provider/mapping/link/batch', params: data });
export const getEhrPwdProviderId = (providerId: number) => Request.get({ url: `/admin-server/ehr/account/pwd/${providerId}` });

export const getHelloKlairtyPageConfigList = (params: IGetHelloKlarityPageManagementResp) => Request.post({ url: '/admin-server/klarity/page/data', params });

export const getHelloKlairtyKlarityPageConditionContent = (pageId: number) => Request.get({ url: '/admin-server/KlarityPageConditionContent/getByPageId', params: { pageId } });

export const updateHelloKlairtyKlarityPageConditionContent = (params: TContentInfo) => Request.put({ url: '/admin-server/KlarityPageConditionContent/updateById', params });

export const updateHelloKlairtyKlarityPageConditionContentStatus = (pageId: number, status: 'A' | 'D') => Request.put({ url: '/admin-server/KlarityPageConditionContent/updateStatusPageId', params: { pageId, status } });

export const getHelloKlairtyKlarityPageFaq = (pageId: number) => Request.get({ url: '/admin-server/KlarityPageFaq/getByPageId', params: { pageId } });

export const updateHelloKlairtyKlarityPageFaq = (params: TFaqInfo) => Request.put({ url: '/admin-server/KlarityPageFaq/updateById', params });

export const updateHelloKlairtyKlarityPageFaqSeq = (params: { id: number, seq: number }[]) => Request.put({ url: '/admin-server/KlarityPageFaq/updateSeqBatchById', params });

export const deleteHelloKlairtyKlarityPageFaq = (faqId: number) => Request.delete({ url: '/admin-server/KlarityPageFaq/deleteById', params: { id: faqId } });

export const getHelloKlairtyKlarityPageInternalLink = (pageId: number) => Request.get({ url: '/admin-server/KlarityPageInternalLink/getByPageId', params: { pageId } });

export const updateHelloKlairtyKlarityPageInternalLinkBatch = (links: TInternalLink[], type: EInternalLinkType, pageId: number) => Request.put({ url: '/admin-server/KlarityPageInternalLink/updateInfoBatch', params: { pageId, type, data: [...links] } });

export const getConfigFeaturedItems = () => Request.get({ url: '/admin-server/configFeaturedItems/getAll' });
export const updateConfigFeaturedItemsInfo = (data: ServerMeta[]) => Request.put({ url: '/admin-server/configFeaturedItems/updateInfo', params: data });
export const updateConfigFeaturedItemsSeq = (data: ServerMeta[]) => Request.put({ url: '/admin-server/configFeaturedItems/updateSeqById', params: data });

export const getAirtableUserDetail = (id: string) => Request.get({ url: `/admin-server/airtable/provider/info/${id}` });

export const getAddOnList = (data: IGeAddOnResp) => Request.post({ url: '/admin-server/provider/addOn/list', params: data });

export const getAddOnDetail = (providerId: string) => Request.get({ url: `/admin-server/provider/addOn/detail/${providerId}` });

export const getW9PreviewLink = (providerId: string) => Request.get({ url: `/admin-server/provider/addOn/w9/${providerId}` });

export const changeAddonStatus = (addonId: number, providerid: string) => Request.put({ url: `admin-server/provider/addOn/active/${addonId}/${providerid}` });

export const getPrivateFilePreview = (resourceKey: string) => Request.post({ url: '/admin-server/common/private/file/signed', params: { resourceKey } });

export const syncEhr = (provider: number) =>
    Request.post({ url: `/admin-server/ehr/sync/provider/${provider}` });

export const getEhrInitStatus = (provider: number) =>
    Request.get({ url: `/admin-server/provider/status/info/${provider}` });

export const exportProviderList = () => Request.get({ url: '/admin-server/provider/profile/list/export' });

export const getContractVersionWithType = () => Request.get({ url: '/admin-server/payPlan/getContractVersionWithType' });

export const exportEHRProviderList = () => Request.get({ url: '/admin-server/provider/mapping/ehr/export' });

export const exportProviderByPorivderId = (providerId: number) => Request.get({ url: 'admin-server/provider/profile/export', params: { providerId } });

export const getCouponFilter = () => Request.get({ url: '/admin-server/PayProvider/getCouponFilterNames' });

export const getPromotionFilterCodes = () => Request.get({ url: '/admin-server/PayProvider/getPromotionFilterCodes' });
