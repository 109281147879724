import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AdditionalPracticeFormFieldEnum, PracticeFormInterface } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FormValidationHelper from 'utils/validation';
import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Tooltip,
    message,
    Select,
    Modal,
    Switch,
} from 'antd';
import ClipboardJS from 'clipboard';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import PhotoList from 'components/PhotoList';
import { EMPTY_PLACEHOLDER, SNAPSHOT_COLOR, URL_LIMIT } from 'constants/common';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { renderChangUI } from 'types/changeHighLight';
import copy from 'copy-to-clipboard';

type FormValue = Partial<PracticeFormInterface> & { appointmentIntegrationUrl?: string, avaAutoReply?: boolean };
interface IProps {
    formName: string;
    showEditButtons?: boolean;
    initFormValue: FormValue;
    initPracticeName: string;
    enable: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onUpdateService: (formName: string, formValue: {
        providerPractice: FormValue
    }) => void;
    isOps?: boolean;
}

const PracticeFrom = forwardRef(({
    isOps,
    initFormValue,
    initPracticeName,
    formName,
    showEditButtons = true,
    enable,
    onUpdateService,
    onEditStatusChange,
}: IProps, ref) => {
    const copyBtnRef = useRef();
    const copyBtnRef2 = useRef();
    // const [practiceNameDisable, setPracticeNameDisable] = useState(false);
    const [practicePhoneDisable, setPracticePhoneDisable] = useState(false);
    const [pracitceFrom] = Form.useForm<PracticeFormInterface>();
    const [setting] = useSettings();
    const taxIdTypeOptions = setting[EnumFields.TAX_ID_TYPE] || [];
    const [showTaxModal, setShowTaxModal] = useState(false);

    const {
        practiceDesc,
        practiceEmail,
        practiceLogo = '',
        practiceName,
        practicePhone,
        practiceWebsite,
        practiceFrontUrl,
        kiwihealthWebsite,
        notHavePhone,
        taxIdType,
        taxId,
        appointmentIntegrationUrl,
        vfdPhoneLine,
        avaAutoReply,
    } = initFormValue || {};

    useEffect(() => {
        pracitceFrom.setFieldsValue(initFormValue);
    }, [initFormValue, pracitceFrom]);

    useEffect(() => {
        let clipboard;
        if (copyBtnRef.current) {
            clipboard = new ClipboardJS(copyBtnRef.current, {
                text: () => practiceFrontUrl,
            });

            clipboard.on('success', () => {
                message.success('Copied to the clipboard');
            });
        }
        return () => clipboard?.destroy && clipboard.destroy();
    }, [practiceFrontUrl, enable]);

    useEffect(() => {
        let clipboard;
        if (copyBtnRef2.current) {
            clipboard = new ClipboardJS(copyBtnRef2.current, {
                text: () => kiwihealthWebsite,
            });

            clipboard.on('success', () => {
                message.success('Copied to the clipboard');
            });
        }
        return () => clipboard?.destroy && clipboard.destroy();
    }, [kiwihealthWebsite, enable]);

    useEffect(() => {
        setPracticePhoneDisable(!!notHavePhone);
    }, [notHavePhone]);

    const getFormValues = useCallback(async () => {
        let validRes = {};
        if (!practiceName) {
            pracitceFrom.setFieldValue(
                AdditionalPracticeFormFieldEnum.NAME,
                initPracticeName,
            );
        }
        try {
            validRes = await pracitceFrom.validateFields();
        } catch (e) {
            validRes = e;
            console.error(e);
        }

        return validRes;
    }, [initPracticeName, pracitceFrom, practiceName]);

    useImperativeHandle(ref, () => ({
        getFromValues: getFormValues,
    }));

    const handleFormFinish = useCallback(async () => {
        pracitceFrom.validateFields().then((values: Partial<PracticeFormInterface>) => {
            if (typeof onUpdateService === 'function') {
                onUpdateService(formName, {
                    providerPractice: {
                        ...initFormValue,
                        ...values,
                    },
                });
            }
        }).catch((e) => {
            console.error(e);
        });
        // onUpdateService(formName, formValue);
        // setSubmitData(formValue);
    }, [formName, initFormValue, onUpdateService, pracitceFrom]);

    const handleCopyLink = useCallback((value?: string) => {
        if (value) {
            copy(value || '', {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, []);

    useEffect(() => {
        if (!practiceName) {
            pracitceFrom.setFieldValue(
                AdditionalPracticeFormFieldEnum.NAME,
                initPracticeName,
            );
        }
    }, [practiceName, pracitceFrom, initPracticeName]);

    const handleDisablePhone = useCallback((e: CheckboxChangeEvent) => {
        pracitceFrom.validateFields();
        pracitceFrom.setFieldValue(AdditionalPracticeFormFieldEnum.TEL, '');
        setPracticePhoneDisable(e.target.checked);
    }, [pracitceFrom]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons || isOps) {
            return !enable ? (
                <div key={`${formName}_disable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, formName);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key={`${formName}_enable`} className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, formName);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
    }, [showEditButtons, isOps, enable, formName, handleFormFinish, onEditStatusChange]);

    const handleHideTaxModal = useCallback(() => setShowTaxModal(false), []);
    const handleShowTaxPopup = useCallback(() => setShowTaxModal(true), []);

    const forceEditableFormItemsRender = useMemo(() => {
        return isOps ? (
            <div className={s.row}>
                <div style={{ flex: 5 }} className={s.displayItem}>
                    <Form.Item
                        label="Practice Front booking link"
                        name={AdditionalPracticeFormFieldEnum.APPOINTMENT_INTEGRATION_URL}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div style={{ flex: 3 }} className={s.displayItem}>
                    <Form.Item
                        label="Virtual phone number"
                        name={AdditionalPracticeFormFieldEnum.VFD_PHONE_LINE}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div style={{ flex: 2 }} className={s.displayItem}>
                    <Form.Item
                        label="Ava auto-reply"
                        name={AdditionalPracticeFormFieldEnum.AVA_AUTO_REPLY}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </div>
            </div>
        ) : '';
    }, [isOps]);

    const nonEditRender = useMemo(() => {
        if (isOps) {
            return (
                !enable ? (
                    <div className={s.row}>
                        <div
                            style={{
                                flex: 6,
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Practice Front booking link</div>
                            <div className={s.value}>
                                {
                                    appointmentIntegrationUrl ? (
                                        <div className={s.valueInner}>
                                            <div className={s.text}>
                                                {appointmentIntegrationUrl}
                                            </div>
                                            <div className={s.duplicateIcon} onClick={() => handleCopyLink(appointmentIntegrationUrl)} />
                                        </div>
                                    ) : 'I want to use the appointment request form'
                                }
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 2,
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Virtual phone line</div>
                            <div className={s.value}>
                                {
                                    vfdPhoneLine ? (
                                        <div className={s.valueInner}>
                                            <div className={s.text}>
                                                {vfdPhoneLine}
                                            </div>
                                            <div className={s.duplicateIcon} onClick={() => handleCopyLink(vfdPhoneLine)} />
                                        </div>
                                    ) : 'Not enabled'
                                }
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 2,
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Ava auto-reply</div>
                            <div className={s.value}>
                                {
                                    avaAutoReply ? 'On' : 'Off'
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <Form
                        form={pracitceFrom}
                        name={formName}
                        className={commonS.formStyle1}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError
                    >
                        {forceEditableFormItemsRender}
                    </Form>
                )
            );
        }

        return '';
    }, [appointmentIntegrationUrl, enable, forceEditableFormItemsRender, formName, handleCopyLink, isOps, pracitceFrom, vfdPhoneLine]);

    return (
        <div className={s.wrap}>
            <h4>
                Practice
                {renderEditButtons}
            </h4>
            <div className={s.content}>
                {
                    !enable ? (
                        <>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label} ${s.required}`}>
                                        Practice name
                                    </div>
                                    <div className={s.value}>
                                        {!practiceName?.replace(/\s+/g, '')
                                            ? initPracticeName
                                            : practiceName}
                                    </div>
                                    {renderChangUI('practiceName', initFormValue, !!isOps)}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label} ${s.required}`}>
                                        Practice phone number
                                    </div>
                                    <div className={s.value}>
                                        {notHavePhone || !practicePhone?.replace(/\s+/g, '')
                                            ? 'I don’t have a practice phone number yet'
                                            : practicePhone}
                                    </div>
                                    {renderChangUI('practicePhone', initFormValue, !!isOps)}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label}`}>Practice email address</div>
                                    <div className={s.value}>
                                        {practiceEmail || EMPTY_PLACEHOLDER}
                                    </div>
                                    {renderChangUI('practiceEmail', initFormValue, !!isOps)}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label}`}>Practice website</div>
                                    <div className={s.value}>
                                        {practiceWebsite || EMPTY_PLACEHOLDER}
                                    </div>
                                    {renderChangUI('practiceWebsite', initFormValue, !!isOps)}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={`${s.label}`}>Practice Tax ID type</div>
                                    <div className={s.value}>
                                        {taxIdTypeOptions.find((item) => item.value === taxIdType)
                                            ?.label ||
                                            taxIdTypeOptions.find((item) => item.value === 'NONE')
                                                ?.label}
                                    </div>
                                    {renderChangUI('taxIdType', initFormValue, !!isOps)}
                                </div>
                            </div>
                            {taxIdType && ['SSN', 'EIN'].includes(taxIdType) && (
                                <div className={s.row}>
                                    <div className={s.displayItem}>
                                        <div className={`${s.label}`}>Practice Tax ID</div>
                                        <div className={s.value}>{taxId || EMPTY_PLACEHOLDER}</div>
                                        {renderChangUI('taxId', initFormValue, !!isOps)}
                                    </div>
                                </div>
                            )}
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Kiwi Health Practice Front link</div>
                                    <div className={s.value}>
                                        <div>{kiwihealthWebsite ? (
                                            <div className={s.valueInner}>
                                                <p>{kiwihealthWebsite}</p>
                                                <div ref={copyBtnRef2} className={s.duplicateIcon} />
                                            </div>
                                        ) : (
                                            EMPTY_PLACEHOLDER
                                        )}
                                        </div>
                                    </div>
                                    {renderChangUI('kiwihealthWebsite', initFormValue, !!isOps)}

                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Practice Front link</div>
                                    <div className={s.value}>
                                        <div>{practiceFrontUrl ? (
                                            <div className={s.valueInner}>
                                                <p>{practiceFrontUrl}</p>
                                                <div ref={copyBtnRef} className={s.duplicateIcon} />
                                            </div>
                                        ) : (
                                            EMPTY_PLACEHOLDER
                                        )}
                                        </div>
                                    </div>
                                    {renderChangUI('practiceFrontUrl', initFormValue, !!isOps)}

                                </div>
                            </div>
                            {nonEditRender}
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Practice description</div>
                                    <div className={s.value}>
                                        {practiceDesc || EMPTY_PLACEHOLDER}
                                    </div>
                                    {renderChangUI('practiceDesc', initFormValue, !!isOps)}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Practice logo</div>
                                    <div className={s.value}>
                                        {practiceLogo ? (
                                            <img className={s.logo} src={practiceLogo} />
                                        ) : (
                                            EMPTY_PLACEHOLDER
                                        )}
                                    </div>
                                    {renderChangUI('practiceLogo', initFormValue, !!isOps, (val: string) => { return <div className={s.value}><img className={s.logo} style={{ border: `2px solid ${SNAPSHOT_COLOR}`, marginTop: '12px' }} src={val} /></div>; })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <Form
                            form={pracitceFrom}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label={
                                            <div className={s.formLabel}>
                                                Practice name<span className={s.required}>*</span>
                                                <Tooltip
                                                    overlayClassName={s.tipCard}
                                                    overlayInnerStyle={{ padding: '24px' }}
                                                    color="#FFFFFF"
                                                    title={
                                                        <div className={s.tipText}>
                                                            Your practice name appears on channels, Practice
                                                            Front, and Kiwi EHR. Without a given name, we'll
                                                            default to '[First Name] [Last Name]'s Practice.
                                                        </div>
                                                    }
                                                >
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        name={AdditionalPracticeFormFieldEnum.NAME}
                                    >
                                        <Input placeholder={initPracticeName} maxLength={200} />
                                    </Form.Item>
                                    <div className={s.tipsLabel}>
                                        If unchanged, &apos;[First Name] [Last Name]&apos;s
                                        Practice&apos; will be your default practice name.
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Practice phone number"
                                        style={{ width: '40%' }}
                                        name={AdditionalPracticeFormFieldEnum.TEL}
                                        validateFirst
                                        rules={[
                                            {
                                                required: !practicePhoneDisable,
                                                message: 'Practice phone number is a required field',
                                            },
                                            FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                        ]}
                                    >
                                        <Input disabled={practicePhoneDisable} maxLength={30} />
                                    </Form.Item>
                                    <Form.Item
                                        name={AdditionalPracticeFormFieldEnum.NOT_HAVE_PHONE}
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            onChange={handleDisablePhone}
                                            checked={practicePhoneDisable}
                                        >
                                            I don’t have a practice phone number yet
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Practice email address"
                                        style={{ width: '40%' }}
                                        name={AdditionalPracticeFormFieldEnum.EMAIL}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Practice website"
                                        style={{ width: '40%' }}
                                        name={AdditionalPracticeFormFieldEnum.WEBSITE}
                                    >
                                        <Input max={URL_LIMIT} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label={
                                            <div className={s.formLabel}>
                                                Practice Tax ID type
                                                <Tooltip
                                                    overlayClassName={s.tipCard}
                                                    overlayInnerStyle={{ padding: '24px' }}
                                                    color="#FFFFFF"
                                                    title={
                                                        <div className={s.tipText}>
                                                            In Kiwi, we accept Employer Identification Number
                                                            (EIN) and Social Security Number (SSN) as Tax ID.
                                                            It will appear on all kiwi invoices and superbills
                                                            while using Kiwi EHR or Klarity.
                                                        </div>
                                                    }
                                                >
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        name={AdditionalPracticeFormFieldEnum.TAX_ID_TYPE}
                                        validateFirst
                                    >
                                        <Select
                                            style={{ width: '40%' }}
                                            options={taxIdTypeOptions}
                                        />
                                    </Form.Item>
                                    <div
                                        className={s.popUpLabel}
                                        onClick={() => handleShowTaxPopup()}
                                    >
                                        Click to know more about Tax ID
                                    </div>
                                </div>
                            </div>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const type = getFieldValue(
                                        AdditionalPracticeFormFieldEnum.TAX_ID_TYPE,
                                    );
                                    if (['SSN', 'EIN'].includes(type)) {
                                        return (
                                            <div className={s.row}>
                                                <div className={s.displayItem}>
                                                    <Form.Item
                                                        label="Practice Tax ID"
                                                        style={{ width: '40%' }}
                                                        name={AdditionalPracticeFormFieldEnum.TAX_ID}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Practice Tax ID is a required field',
                                                            },
                                                        ]}
                                                    >
                                                        <Input maxLength={200} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        );
                                    }
                                }}
                            </Form.Item>

                            <div className={s.row}>
                                <div className={`${s.displayItem} ${s.duplicateDisplayItem}`}>
                                    <Form.Item
                                        label={
                                            <div className={s.formLabel}>
                                                Practice Front link
                                                <Tooltip
                                                    overlayClassName={s.tipCard}
                                                    overlayInnerStyle={{ padding: '24px' }}
                                                    color="#FFFFFF"
                                                    title={
                                                        <div className={s.tipText}>
                                                            URL will be available once you activate Practice
                                                            Front.
                                                        </div>
                                                    }
                                                >
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{ width: '40%' }}
                                        name={AdditionalPracticeFormFieldEnum.PRACTICE_FRONT_URL}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                    <div ref={copyBtnRef} className={s.duplicateIcon} />
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={`${s.displayItem} ${s.duplicateDisplayItem}`}>
                                    <Form.Item
                                        label={
                                            <div className={s.formLabel}>
                                                Kiwi Health Practice Front link
                                                <Tooltip
                                                    overlayClassName={s.tipCard}
                                                    overlayInnerStyle={{ padding: '24px' }}
                                                    color="#FFFFFF"
                                                    title={
                                                        <div className={s.tipText}>
                                                            URL will be available once you activate Practice
                                                            Front.
                                                        </div>
                                                    }
                                                >
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                        }
                                        style={{ width: '40%' }}
                                        name={AdditionalPracticeFormFieldEnum.KIWI_HEALTH_WEBSITE}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                    <div ref={copyBtnRef2} className={s.duplicateIcon} />
                                </div>
                            </div>

                            {forceEditableFormItemsRender}

                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Practice description"
                                        name={AdditionalPracticeFormFieldEnum.DESCRIPTION}
                                    >
                                        <Input.TextArea
                                            className={s.textarea}
                                            showCount
                                            maxLength={800}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.formItemLabel}>
                                        Practice logo <span className={s.required} />
                                    </div>
                                    <div className={s.subLabel}>
                                        To ensure optimal display, please upload your{' '}
                                        <strong>logo with a transparent background</strong>
                                    </div>
                                    <Form.Item
                                        // label="Practice logo"
                                        name={AdditionalPracticeFormFieldEnum.LOGO}
                                        valuePropName="data"
                                    >
                                        <PhotoList
                                            min={1}
                                            data={[practiceLogo]}
                                            enable
                                            responseType="string"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    )}
            </div>
            <Modal
                title="Understanding Tax ID"
                centered
                open={showTaxModal}
                onCancel={handleHideTaxModal}
                width={1300}
                footer={null}
            >
                <div className={s.modalContent}>
                    <div className={s.list}>
                        <div className={s.listItem}>
                            <p className={s.question}>
                                Why do I need to input a Tax ID in the UniProfile?
                            </p>
                            <p className={s.answer}>
                                A Tax ID will appear on all kiwi invoices and superbills while
                                using Kiwi EHR or Klarity. The superbills is what the patient
                                submits to the insurance companies for reimbursements. The
                                insurance payer then looks up the Tax ID to verify providers
                                identity. Therefore, a Tax ID is required for generating
                                invoices and processing payments , ensuring compliance with
                                financial regulations.
                            </p>
                        </div>
                        <div className={s.listItem}>
                            <p className={s.question}>What is EIN?</p>
                            <p className={s.answer}>
                                An Employer Identification Number (EIN) is a unique identifier
                                for businesses, used for tax purposes.
                            </p>
                        </div>
                        <div className={s.listItem}>
                            <p className={s.question}>What is SSN?</p>
                            <p className={s.answer}>
                                A Social Security Number (SSN) is a personal identifier
                                assigned to U.S. citizens and some residents for social
                                security and taxation.
                            </p>
                        </div>
                        <div className={s.listItem}>
                            <p className={s.question}>
                                What is the difference between EIN and SSN?
                            </p>
                            <p className={s.answer}>
                                EIN is for businesses, while SSN is for individuals. EIN is
                                recommended for privacy and professional transactions.
                            </p>
                        </div>
                        <div className={s.listItem}>
                            <p className={s.question}>How to apply for an EIN?</p>
                            <p className={s.answer}>
                                You can apply for an EIN online through the IRS website (
                                <a href="www.irs.gov" target="_blank">
                                    www.irs.gov
                                </a>
                                ). Typically it takes 24-48 hours to process your request.
                            </p>
                        </div>
                        <div className={s.listItem}>
                            <p className={s.question}>
                                Why is an EIN recommended over an SSN for your Tax ID?
                            </p>
                            <p className={s.answer}>
                                We don&apos;t recommend using SSN as your Tax ID on invoices
                                because it poses a risk to your personal security. Exposing
                                your SSN, especially on documents like invoices that are
                                widely circulated, can lead to identity theft and other forms
                                of financial fraud. An EIN is a safer alternative that
                                provides the necessary tax identification for your business
                                without compromising personal information.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default PracticeFrom;
