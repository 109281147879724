/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import s from './s.module.less';
import { ProviderChannelService } from 'types/channelApplicationProvider';
import CommonForm from './components/CommonForm';
import { Link } from 'react-router-dom';
// import { StepValue } from 'pages/CreateUniProfilePage/types';
import { INTAKEFORM_CREATE_URL } from 'constants/common';
// import ProviderStore from 'store/Provider';
import { NoticeType } from 'antd/es/message/interface';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
// import remove from 'lodash/remove';
import { providerServiceDelete, providerServiceUpdate } from 'api/appointmentSetting';
import { deleteProviderService, updateProviderService } from 'api/operation';
import ServiceUpdateModal from 'components/ServiceUpdateModal';
import { transAddressDataToServiceOptions } from 'utils/common';
import { PracitceAddressForm } from 'types/practiceFront';
import { ServiceExt } from 'types/provider';

// interface ISubmitChannelRelationshipData {
//     providerChannelServiceIdList: Array<number>;
// }
// type ISubmitChannelRelationshipData = Pick<IProfileFormSubmit, 'providerChannelServiceIdList'>;
interface IProps {
    addressList?: Array<PracitceAddressForm>;
    isOps?: boolean;
    // 运营端需要传当前操作的用户id
    providerId?: number;
    // showTypeCheckbox?: boolean;
    showEditButtons?: boolean;
    initFormValue: Array<ServiceExt>;
    refetch: () => void;
    // onSubmit: (data: ISubmitChannelRelationshipData, ste: StepValue) => void;
    onShowMessage: (type: NoticeType, content: string) => void;
}

const Services = ({
    addressList = [],
    isOps,
    providerId,
    showEditButtons = true,
    initFormValue = [],
    refetch,
    onShowMessage,
}: IProps) => {
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showRemoveServiceModal, setShowRemoveServiceModal] = useState(false);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [currentSubmittingFormId, setCurrentSubmittingFormId] = useState<number>(-1);
    const [currentRemoveServiceId, setCurrentRemoveServiceId] = useState<number>(-1);
    const [editStatus, setEditStatus] = useState(false);
    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');
    const [addressOptions, setAddressOptions] = useState<Array<{ value: number, label: string, isDefault: boolean }>>([]);

    useEffect(() => {
        if (addressList?.length > 0) {
            const addressResp = transAddressDataToServiceOptions(addressList);

            setAddressOptions(addressResp);
        }
    }, [addressList]);

    // forms refs
    const formRefs = useRef<any>([]);

    useEffect(() => {
        formRefs.current = formRefs.current.slice(0, initFormValue.length);
    }, [initFormValue]);

    //mock
    // const [isInPerson, setIsInPerson] = useState(true);

    const handleHideLeaveModal = useCallback(() => setShowLeaveModal(false), []);
    const handleHideRemoveModal = useCallback(() => setShowRemoveServiceModal(false), []);

    const handleShowRemoveModal = useCallback((id: number) => {
        setCurrentRemoveServiceId(id);
        setShowRemoveServiceModal(true);
    }, []);

    const handleShowAddServiceModal = useCallback(() => {
        setShowAddServiceModal(true);
    }, []);

    const handleRemoveService = useCallback(async () => {
        try {
            const fn = isOps ? deleteProviderService : providerServiceDelete;
            await fn(currentRemoveServiceId).finally(() => refetch());
            setShowRemoveServiceModal(false);
        } catch (error) {
            console.error(error);
        }
    }, [currentRemoveServiceId, isOps, refetch]);

    const handleEditStatusChange = useCallback((v: boolean, id: number) => {
        if (!globalFormsEditStatus || !v) {
            setEditStatus(v);
            setCurrentSubmittingFormId(id);
            setGloalEditStatus('globalEditStatus', v);
        } else {
            onShowMessage('error', 'Please save your changes before proceeding');
        }
    }, [globalFormsEditStatus, onShowMessage, setGloalEditStatus]);

    const handleSubmitSuccess = useCallback(() => {
        handleEditStatusChange(false, currentSubmittingFormId);
        refetch();
    }, [currentSubmittingFormId, handleEditStatusChange, refetch]);

    const handleSubmit = useCallback(async (id: number, formData?: ProviderChannelService, callback?: () => void) => {
        const data = {
            ...(formData || {}),
            telehealthType: formData?.telehealthType?.join(',') || '',
            personType: formData?.personType?.join(',') || '',
        };
        if (!formData?.enableSlidingScale) {
            data.highestPrice = undefined;
            data.lowestPrice = undefined;
        }
        const updateFn = isOps ? updateProviderService : providerServiceUpdate;
        const requestResult = await updateFn(data, id);

        if (requestResult && !requestResult.error) {
            callback?.();
        } else {
            onShowMessage('error', requestResult.error);
        }
        // setEnable(false);

        refetch();
    }, [isOps, refetch, onShowMessage]);

    const handleLeave = useCallback(() => {
        window.location.href = INTAKEFORM_CREATE_URL;
    }, []);

    const onCreateServiceSuccess = useCallback(() => {
        setShowAddServiceModal(false);
        refetch();
    }, [refetch]);

    const handleConfirmUpdate = useCallback((id: number, formValue: ProviderChannelService) => {
        setCurrentSubmittingFormId(id);

        handleSubmit(id, formValue, () => {
            handleSubmitSuccess();
        });
    }, [handleSubmit, handleSubmitSuccess]);

    const emptyService = useMemo(() => {
        return (
            <div className={s.emptyBox}>
                <p>No available service type to be selected</p>
                <div>Please go to <Link to="/dashboard/appointment-setting"><a>{'Appointment > Setting > Services'}</a></Link> to create your service type first</div>
            </div>
        );
    }, []);

    return (
        <div className={s.module}>
            {
                showAddServiceModal &&
                <ServiceUpdateModal
                    addressList={addressOptions}
                    isOps={isOps}
                    providerId={providerId}
                    onCancel={() => setShowAddServiceModal(false)}
                    onSuccess={onCreateServiceSuccess}
                />
            }
            <Modal
                title="Remove the service"
                centered
                open={showRemoveServiceModal}
                okText="Remove"
                onOk={handleRemoveService}
                onCancel={handleHideRemoveModal}
                width={543}
            >
                <p className={s.modalContent}>
                    Please note that this action cannot be undone and once a service is removed, it will be permanently removed from any channel pages where it is currently listed.
                    <br />
                    Do you confirm to remove this service from your UniProfile?
                </p>
            </Modal>
            <Modal
                title="Leave the page?"
                centered
                open={showLeaveModal}
                okText="Leave the page"
                onOk={handleLeave}
                onCancel={handleHideLeaveModal}
                width={543}
            >
                <p className={s.modalContent}>You have made changes that have not been saved. Are you sure you want to leave the page now? You will lose all the progress on this page.</p>
            </Modal>
            <div className={s.wrap}>
                <div className={s.module}>
                    <h3>
                        <span>Service</span>
                        <div className={s.buttonsWrap}>
                            { showEditButtons && <Button className={s.showPhotoModalButton} type="default" size="small" onClick={handleShowAddServiceModal}>+ Add a new service</Button> }
                        </div>
                    </h3>
                    {
                        initFormValue && initFormValue.length > 0 ?
                            initFormValue?.map((service, index) => {
                                const { id } = service;
                                const enableEditing = id === currentSubmittingFormId && editStatus;

                                return (
                                    <div key={id} id={`Services_${index + 1}`}>
                                        <CommonForm
                                            addressList={addressOptions}
                                            isOps={isOps}
                                            showEditButtons={showEditButtons}
                                            enable={enableEditing}
                                            key={id}
                                            ref={(el) => { formRefs.current[index] = el; }}
                                            formData={service}
                                            onEditStatusChange={handleEditStatusChange}
                                            onUpdateService={handleConfirmUpdate}
                                            // onShowUpdateModal={handleShowUpdateModal}
                                            onRemoveService={handleShowRemoveModal}
                                        />
                                    </div>
                                );
                            }) : emptyService
                    }
                </div>
            </div>
        </div>
    );
};

export default Services;
