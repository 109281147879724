import React from 'react';
import { TableProps } from 'antd/lib/table';
import { Table } from 'antd';
import s from './s.module.less';

type CommonTableProps<T> = {
    title?:string
    data: T[]
} & TableProps<T>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const CommonTable = <T extends object,>(Props:CommonTableProps<T>) => {
    const { title, data, ...reset } = Props;
    return (
        <div className={s.wrap}>
            <Table<T>
                dataSource={data}
                {...reset}
            />
        </div>
    );
};

export default CommonTable;
