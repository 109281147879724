import React, { useCallback } from 'react';
import { Input } from 'antd';
import s from './s.module.less';
import { replaceLineBreak } from '../../util';

interface IProps {
    placeholder?: string;
    value?: string;
    onChange?: (v: string) => void;
}

const AutoLineBreakTextArea = ({
    value = '',
    placeholder,
    onChange,
}: IProps) => {
    const handleTreatmentTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;

        if (typeof onChange === 'function') {
            onChange(text);
        }
    }, [onChange]);

    const handleTreatmentKeypress = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            const newTreatmentText = replaceLineBreak(value);

            if (typeof onChange === 'function') {
                onChange(newTreatmentText);
            }
        }
    }, [onChange, value]);

    return (
        <Input.TextArea
            className={s.textarea}
            showCount
            maxLength={800}
            value={value}
            onChange={handleTreatmentTextChange}
            onKeyPress={handleTreatmentKeypress}
            placeholder={placeholder}
        />
    );
};

export default AutoLineBreakTextArea;
