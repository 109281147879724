import { Request } from '../request';
import { Setting } from 'types/api';
import { EnumFields } from 'types/enumerationData';
import type { ServerMeta } from 'types/common';

export const uploadFile = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/oms-server/uploadFile',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );

export const getSettings = () =>
    Request.get<Setting[]>({
        url: '/admin-server/setting/list',
        disableErrorNote: true,
    });

export const getCalendarColors = () =>
    Request.get<Setting[]>({
        url: '/schedule-server/openapi/admin/listSettingByType?type=color',
        disableErrorNote: true,
    });

export const getAdminCalendarColors = () =>
    Request.get<Setting[]>({
        url: '/admin-server/setting/listByType?type=color',
        disableErrorNote: true,
    });

export const getListSetting = () => Request.get({
    url: '/schedule-server/openapi/admin/listSetting',
    disableErrorNote: true,
});

export const getAdminListSetting = () => Request.get({
    url: '/admin-server/setting/list',
    disableErrorNote: true,
});

export const getListSettingByType = (type: EnumFields) => Request.get({
    url: '/schedule-server/openapi/admin/listSettingByType',
    params: { type },
    disableErrorNote: true,
});

export const getConfigFeaturedItems = () =>
    Request.get<ServerMeta[]>({
        url: '/schedule-server/configFeaturedItems/getAll',
        disableErrorNote: true,
    });

export const uploadProviderFile = (data: FormData) =>
    Request.post<FormData>(
        {
            url: '/oms-server/uploadPrivateFile',
            params: data,
            config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        },
    );
