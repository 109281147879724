/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Select, Popover, Tag } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import s from './s.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { KlarityService, ServerMeta } from 'types/common';
import { EnumFields } from 'types/enumerationData';
import AvaIcon from 'assets/common/ava.png';

type IProps = {
    onSelectedDataChange: (data: KlarityService[]) => void;
    onServerIdStateMapChange: (data: Record<string, ServerMeta[]>) => void;
    recommendData?: KlarityService[];
    otherData?: KlarityService[];
    allowStates?: ServerMeta[];
};

const SelectService = (props: IProps) => {
    const { onSelectedDataChange, onServerIdStateMapChange, recommendData = [], otherData = [], allowStates = [] } = props;
    const [selectData, setSelectData] = React.useState<number[]>([]);
    const [statesOptions] = useGetSettingData(EnumFields.STATE);
    //const [conditionOptions] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const [serverIdStateMap, setServerIdStateMap] = React.useState<Record<string, string[]>>({});
    const [hasInitState, setHasInitState] = React.useState<boolean>(false);

    const stateLength = allowStates.length;

    React.useEffect(() => {
        if (allowStates && allowStates.length > 0 && otherData && otherData.length > 0 && !hasInitState) {
            const initMap: Record<string, string[]> = {};
            recommendData?.forEach((item) => {
                initMap[`${item.id}`] = allowStates.map((st) => st.content);
            });
            otherData?.forEach((item) => {
                initMap[`${item.id}`] = allowStates.map((st) => st.content);
            });
            setHasInitState(true);
            setServerIdStateMap(initMap);
        }
    }, [recommendData, otherData, allowStates, hasInitState, stateLength]);

    React.useEffect(() => {
        const selectServicer = [...recommendData, ...otherData].filter((item) => !!selectData.includes(item.id));
        onSelectedDataChange(selectServicer);
    }, [otherData, recommendData, selectData]);

    React.useEffect(() => {
        const data: Record<string, ServerMeta[]> = {};
        Object.keys(serverIdStateMap).forEach((key: string) => {
            const stateKeys = serverIdStateMap[key];
            data[key] = (statesOptions as ServerMeta[])?.filter((item: ServerMeta) => stateKeys.includes(item.content) || stateKeys.includes(item.dictKey));
        });
        onServerIdStateMapChange(data);
    }, [serverIdStateMap]);

    React.useEffect(() => {
        if (recommendData) {
            setSelectData(recommendData?.filter((item) => !item.have).map((item) => item.id));
        }
    }, [recommendData]);

    return (
        <div className={s.wrap}>
            <h4>
                <img src={AvaIcon} /> has selected the specialty templates tailored to your conditions treated,
                license, and state. Please choose at least 1 specialty template to proceed.
                If you have multiple specialties, feel free to select all.
            </h4>
            <div className={s.listWrap}>
                {
                    recommendData.map((item: KlarityService) => {
                        const moreCondition: string[] = [];
                        const displayCondition: string[] = [];
                        let maxLength = 25;
                        item.commonCondition?.split(',')?.forEach((cond: string, inx: number) => {
                            const displayName = cond;
                            const len = displayName.length;
                            if ((maxLength < 0 || maxLength < len) && inx > 0) {
                                moreCondition.push(cond);
                            } else {
                                displayCondition.push(cond);
                            }
                            maxLength -= len;
                        });
                        const hasMoreCondition = moreCondition.length > 0;
                        const isSelect = selectData.includes(item.id);
                        const hasSet = !!item.have;
                        return (
                            <div
                                onClick={() => {
                                    if (hasSet) return;
                                    if (!isSelect) {
                                        const newData = [...selectData];
                                        newData.push(item.id);
                                        setSelectData(newData);
                                    } else {
                                        const newData = selectData.filter((id) => id !== item.id);
                                        setSelectData(newData);
                                    }
                                }}
                                className={`${s.item} ${isSelect ? s.itemSelected : ''} ${hasSet ? s.itemSeted : ''}`}
                                key={item.id}
                            >
                                <div className={s.itemIn}>
                                    <div className={s.header}>
                                        <div className={s.left}>
                                            <img src={item.iconUrl} />
                                        </div>
                                        <div className={s.middle}>
                                            <div className={s.title}>
                                                <span className={s.name}>{item.specialtyName}</span>
                                                <Popover content={<div className={s.toolWrap} dangerouslySetInnerHTML={{ __html: item.description }} />} trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </Popover>
                                            </div>
                                            <div className={s.content}>
                                                {item.description}
                                            </div>
                                            <div className={s.conditionWrap}>
                                                {
                                                    displayCondition?.map((condi: string) => {
                                                        return (
                                                            <div className={s.condi} key={condi}>{condi}</div>
                                                        );
                                                    })
                                                }
                                                {
                                                    hasMoreCondition &&
                                                    <Popover content={<div className={s.toolWrap}>{moreCondition?.join(', ')}</div>} trigger="hover">
                                                        <div className={s.more}>{moreCondition.length} more</div>
                                                    </Popover>

                                                }
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            {
                                                !hasSet &&
                                                <span className={s.selectIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 31.5C25.232 31.5 31.5 25.232 31.5 17.5C31.5 9.76801 25.232 3.5 17.5 3.5C9.76801 3.5 3.5 9.76801 3.5 17.5C3.5 25.232 9.76801 31.5 17.5 31.5ZM23.9874 15.2374C24.6708 14.554 24.6708 13.446 23.9874 12.7626C23.304 12.0791 22.196 12.0791 21.5126 12.7626L15.75 18.5251L13.4874 16.2626C12.804 15.5791 11.696 15.5791 11.0126 16.2626C10.3291 16.946 10.3291 18.054 11.0126 18.7374L14.5126 22.2374C15.196 22.9209 16.304 22.9209 16.9874 22.2374L23.9874 15.2374Z" fill="#00816B" />
                                                    </svg>
                                                </span>
                                            }
                                            {
                                                hasSet &&
                                                <span className={s.tag}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0241 3.17574C10.2584 3.41005 10.2584 3.78995 10.0241 4.02426L5.22407 8.82426C4.98975 9.05858 4.60986 9.05858 4.37554 8.82426L1.97554 6.42426C1.74123 6.18995 1.74123 5.81005 1.97554 5.57574C2.20986 5.34142 2.58975 5.34142 2.82407 5.57574L4.7998 7.55147L9.17554 3.17574C9.40985 2.94142 9.78975 2.94142 10.0241 3.17574Z" fill="#00816B" />
                                                    </svg>
                                                    <span>
                                                        Added
                                                    </span>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className={s.stateWrap}>
                                        <h5>
                                            Practice states
                                            <Popover placement="bottomRight" content={<div className={s.toolWrap}>Only states where you are licensed are available for selection. If you are licensed in other states, please update your licenses in License section in UniProfile.</div>} trigger="hover">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M6.85638 7.5C7.31398 6.52901 8.54869 5.83333 10 5.83333C11.841 5.83333 13.3334 6.95262 13.3334 8.33333C13.3334 9.49953 12.2687 10.4792 10.8286 10.7555C10.3766 10.8422 10 11.2064 10 11.6667M10 14.1667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </Popover>

                                        </h5>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className={s.stateSelect}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Please select"
                                                disabled={hasSet}
                                                value={serverIdStateMap[`${item.id}`] || []}
                                                onChange={(val) => {
                                                    if (val && val.length === 0) {
                                                        return;
                                                    }
                                                    const id = `${item.id}`;
                                                    const newMap = { ...serverIdStateMap };
                                                    newMap[id] = val;
                                                    setServerIdStateMap(newMap);
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    allowStates.map((st: ServerMeta) => {
                                                        return (
                                                            <Select.Option key={st.content} value={st.content}>
                                                                {st.content}
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <h3>Other specialties you may want to explore:</h3>
            <div className={s.listWrap}>
                {
                    otherData.map((item: KlarityService) => {
                        const moreCondition: string[] = [];
                        const displayCondition: string[] = [];
                        let maxLength = 30;
                        item.commonCondition?.split(',')?.forEach((cond: string, inx: number) => {
                            const displayName = cond;
                            const len = displayName.length;
                            if ((maxLength < 0 || maxLength < len) && inx > 0) {
                                moreCondition.push(cond);
                            } else {
                                displayCondition.push(cond);
                            }
                            maxLength -= len;
                        });
                        const hasMoreCondition = moreCondition.length > 0;
                        const isSelect = selectData.includes(item.id);
                        const hasSet = !!item.have;
                        return (
                            <div
                                onClick={() => {
                                    if (hasSet) {
                                        return;
                                    }
                                    if (!isSelect) {
                                        const newData = [...selectData];
                                        newData.push(item.id);
                                        setSelectData(newData);
                                    } else {
                                        const newData = selectData.filter((id) => id !== item.id);
                                        setSelectData(newData);
                                    }
                                }}
                                className={`${s.item} ${isSelect ? s.itemSelected : ''} ${hasSet ? s.itemSeted : ''}`}
                                key={item.id}
                            >
                                <div className={s.itemIn}>
                                    <div className={s.header}>
                                        <div className={s.left}>
                                            <img src={item.iconUrl} />
                                        </div>
                                        <div className={s.middle}>
                                            <div className={s.title}>
                                                <span className={s.name}>{item.specialtyName}</span>
                                                <Popover content={<div className={s.toolWrap} dangerouslySetInnerHTML={{ __html: item.description }} />} trigger="hover">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </Popover>
                                            </div>
                                            <div className={s.content}>
                                                {item.description}
                                            </div>
                                            <div className={s.conditionWrap}>
                                                {
                                                    displayCondition?.map((condi: string) => {
                                                        return (
                                                            <div className={s.condi} key={condi}>{condi}</div>
                                                        );
                                                    })
                                                }
                                                {
                                                    hasMoreCondition &&
                                                    <Popover content={<div className={s.toolWrap}>{moreCondition?.join(', ')}</div>} trigger="hover">
                                                        <div className={s.more}>{moreCondition.length} more</div>
                                                    </Popover>

                                                }
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            {
                                                !hasSet &&
                                                <span className={s.selectIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 31.5C25.232 31.5 31.5 25.232 31.5 17.5C31.5 9.76801 25.232 3.5 17.5 3.5C9.76801 3.5 3.5 9.76801 3.5 17.5C3.5 25.232 9.76801 31.5 17.5 31.5ZM23.9874 15.2374C24.6708 14.554 24.6708 13.446 23.9874 12.7626C23.304 12.0791 22.196 12.0791 21.5126 12.7626L15.75 18.5251L13.4874 16.2626C12.804 15.5791 11.696 15.5791 11.0126 16.2626C10.3291 16.946 10.3291 18.054 11.0126 18.7374L14.5126 22.2374C15.196 22.9209 16.304 22.9209 16.9874 22.2374L23.9874 15.2374Z" fill="#00816B" />
                                                    </svg>
                                                </span>
                                            }
                                            {
                                                hasSet &&
                                                <span className={s.tag}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0241 3.17574C10.2584 3.41005 10.2584 3.78995 10.0241 4.02426L5.22407 8.82426C4.98975 9.05858 4.60986 9.05858 4.37554 8.82426L1.97554 6.42426C1.74123 6.18995 1.74123 5.81005 1.97554 5.57574C2.20986 5.34142 2.58975 5.34142 2.82407 5.57574L4.7998 7.55147L9.17554 3.17574C9.40985 2.94142 9.78975 2.94142 10.0241 3.17574Z" fill="#00816B" />
                                                    </svg>
                                                    <span>
                                                        Added
                                                    </span>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className={s.stateWrap}>
                                        <h5>
                                            Practice states
                                            <Popover placement="bottomRight" content={<div className={s.toolWrap}>Only states where you are licensed are available for selection. If you are licensed in other states, please update your licenses in License section in UniProfile</div>} trigger="hover">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M6.85638 7.5C7.31398 6.52901 8.54869 5.83333 10 5.83333C11.841 5.83333 13.3334 6.95262 13.3334 8.33333C13.3334 9.49953 12.2687 10.4792 10.8286 10.7555C10.3766 10.8422 10 11.2064 10 11.6667M10 14.1667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </Popover>

                                        </h5>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className={s.stateSelect}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Please select"
                                                value={serverIdStateMap[`${item.id}`] || []}
                                                onChange={(val) => {
                                                    if (val && val.length === 0) {
                                                        return;
                                                    }
                                                    const id = `${item.id}`;
                                                    const newMap = { ...serverIdStateMap };
                                                    newMap[id] = val;
                                                    setServerIdStateMap(newMap);
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                {
                                                    allowStates.map((st: ServerMeta) => {
                                                        return (
                                                            <Select.Option key={st.content} value={st.content}>
                                                                {st.content}
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default SelectService;
