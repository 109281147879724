import { EAddOnProductCode, IEHRCheckoutInput, IOnboardingInput } from 'types/ehr';
import { Request } from '../request';
import { TAddToCartData } from './types';
import { EStepTypes } from 'types/common';

export const getAddonProductList = () => Request.get({ url: '/schedule-server/product/getAddOnList' });

export const getAssistantList = (productCode:EAddOnProductCode.ASSISTANT_SEAT | EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS | EAddOnProductCode.ASSISTANT_EPRESCRIPTION) => Request.get({ url: `/schedule-server/provider/assistant/addOn/list?productCode=${productCode}` });

export const getCollaboratingPhysicianList = (productCode:EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT | EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS | EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION) => Request.get({ url: `/schedule-server/provider/physician/addOn/list?productCode=${productCode}` });

export const getAddonShoppingCart = () => Request.get({ url: '/schedule-server/ehrAddonShoppingCart/getCarAll' });

export const getAddonShoppingCartCount = () => Request.get({ url: '/schedule-server/ehrAddonShoppingCart/getCartCount' });

export const addToShoppingCart = (productCode:EAddOnProductCode, qty:number, data?:TAddToCartData) => Request.put({ url: '/schedule-server/ehrAddonShoppingCart/addCart',
    params: {
        productCode,
        productQty: qty,
        ...(data || {}),
    },
});

export const removeFromShoppingCart = (productCode:EAddOnProductCode) => Request.delete({ url: `/schedule-server/ehrAddonShoppingCart/remove?productCode=${productCode}` });

export const getOnboardingStepInfo = (stepType: EStepTypes = EStepTypes.EHR_SET_UP) => Request.get({ url: `/schedule-server/provider/step/stepinfo?stepTypeEnum=${stepType}` });

export const onboardingSubmit = (data: IOnboardingInput) => Request.post({ url: '/schedule-server/provider/step/process', params: data });
export const getAddOnUniprofileInfo = () => Request.get({ url: '/schedule-server/provider/addOn/uniprofile/info' });

export const shoppingCartCheckout = (data: IEHRCheckoutInput) => Request.post({ url: '/schedule-server/payment/checkout', params: data });

export const getEhrAccount = () => Request.get({ url: '/schedule-server/provider/ehr/account' });

export const getEhrPwd = () => Request.get({ url: '/schedule-server/provider/ehr/pwd' });

export const getPrivateFilePreview = (resourceKey: string) => Request.post({ url: '/schedule-server/common/private/file/signed', params: { resourceKey } });
export const checkIsPaymentFailed = () => Request.get({ url: '/schedule-server/payment/anyPayFailed' });

export const getPayList = () => Request.get({ url: '/schedule-server/payment/payList' });

export const updateEFax = (data: {
    eFaxOption: string;
    eFax: string;
}) => Request.put({ url: '/schedule-server/provider/addOn/efax/info/update', params: data });
